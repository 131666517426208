import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Navigation } from "./components/Website";
import "bootstrap/dist/css/bootstrap.css";
import { BusinessNavigation } from "./components/Business";
import {
  AddAmenities,
  Calendar,
  AddBranch,
  AddBundles,
  AddCategory,
  AddClient,
  AddService,
  AddTeams,
  Amenities,
  Bookings,
  Bundles,
  BusinessSeting,
  Category,
  Clients,
  EditAmenity,
  EditBundle,
  EditPackage,
  Error404,
  Login,
  Packages,
  Services,
  SignUp,
  Teams,
  ViewAmenity,
  ViewBundle,
  ViewCategory,
  ViewClient,
  ViewPackage,
  ViewService,
  ViewTeam,
  RollManagement,
  Dashboard,
  Notification,
  Branch,
  AssignServicesToBusiness,
  TeamTitle,
  BranchHome,
  BranchServices,
  BranchTeams,
  BranchAmenities,
  ViewBranchService,
  AddBranchTeams,
  ViewBranchTeams,
  AddBranchAmenity,
  EditBranchAmenity,
  ViewBranchAmenity,
  ViewBranch,
  AddBranchClient,
  EditBranchClient,
  ViewBranchClient,
  BranchClients,
  Promotion,
  AddPromotion,
  MemberShip,
  AddMemberShip,
  ViewMemberShip,
  Sales,
  Chat,
  AddBranchService,
  BranchPackages,
  EditBranchPackage,
  ViewBranchPackage,
  BranchMemberShip,
  AddBranchMemberShip,
  ViewBranchMemberShip,
  Setting,
  Reports,
} from "./pages/Business";
import { Home, NotificationWeb, Salon } from "./pages/Website";
import ResetPassword from "./pages/Business/Login/ResetPassword";
import UpdateBusinessAdmin from "./pages/AdminRedirect";
import { useSelector } from "react-redux";
import Business404 from "./pages/Business/Error404/Business404";
import ChatBox from "./pages/Business/Chat/ChatBox";
import WellnezzaAddPromotion from "./pages/Business/Promotion/WellnezzaAddPromotion";
import AssignServicesToTeam from "./pages/Business/AssignServicesToTeam";
import RolesList from "./pages/Business/RolesList";
import TeamVerification from "./pages/Business/TeamVerification/TeamVerification";
import AllServices from "./pages/Business/AllServices";
import Wellnezza from "./pages/Business/Wellnezza/Wellnezza";
import Publish from "./pages/Business/Publish";

const App = () => {
  let { mainModuleRoles, subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const businessData = localStorage.getItem('business_data') ? JSON.parse(localStorage.getItem('business_data')!) : null

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route path="/" element={<Home />} />
        <Route path="notification" element={<NotificationWeb />} />
        <Route path="salon" element={<Salon />} />
      </Route>

      <Route path="*" element={<Error404 />} />
      <Route path="/updateBusinessinfo/:UID/:BID/:TOKEN" element={<UpdateBusinessAdmin />} />
      <Route path="business">
        <Route path="team-verification" element={<TeamVerification />} />
        <Route path="" element={<StopRedirection />}>
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="resetPassword/:uid" element={<ResetPassword />} />
        </Route>
        <Route path="" element={<BusinessNavigation />}>
          {+businessData?.stepCompleted > 9 && <Route index element={<Navigate to="dashboard/*" />} />}
          {+businessData?.stepCompleted <= 6 && <Route index element={<Navigate to="branch" />} />}
          {+businessData?.stepCompleted === 7 && <Route index element={<Navigate to="services" />} />}
          {+businessData?.stepCompleted === 8 && <Route index element={<Navigate to="new-teams" />} />}
          {+businessData?.stepCompleted === 9 && <Route index element={<Navigate to="wellnezza/business" />} />}
          <Route path="*" element={<Business404 />} />

          {/* main modules  */}
          {mainModuleRoles?.Dashboard?.View && (
            <Route path="dashboard" element={<Navigate to="dashboard/today" />} />)}

          {mainModuleRoles?.Dashboard?.View && (<Route path="dashboard/*" element={<Dashboard />} />)}

          {(mainModuleRoles?.Bookings?.ViewOwnBookings || mainModuleRoles?.Bookings?.ViewAllBookings) && <Route path="calendar" element={<Calendar />} />}
          {(mainModuleRoles?.Bookings?.ViewOwnBookings || mainModuleRoles?.Bookings?.ViewAllBookings) && <Route path="bookings" element={<Bookings />} />}
          {(mainModuleRoles?.Reports?.Bookings || mainModuleRoles?.Reports?.Payment || mainModuleRoles?.Reports?.Team || mainModuleRoles?.Reports?.Promotion || mainModuleRoles?.Reports?.Branch) && <Route path="Reports" element={<Reports />} />}

          {mainModuleRoles?.Customer?.View && <Route path="clients" element={<Clients />} />}
          {mainModuleRoles?.Customer?.View && <Route path="clients/addClient" element={<Clients />} />}
          {mainModuleRoles?.Customer?.View && <Route path="clients/edit-client" element={<Clients />} />}
          {mainModuleRoles?.Customer?.View && <Route path="clients/view-client" element={<ViewClient />} />}

          {mainModuleRoles?.Promotion?.View && <Route path="promotion" element={<Promotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/addPromotion/*" element={<AddPromotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/WellnezzaAddPromotion/*" element={<WellnezzaAddPromotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/edit-promotion/*" element={<AddPromotion />} />}
          {mainModuleRoles?.Promotion?.View && <Route path="promotion/wellnezza-edit-promotion/*" element={<WellnezzaAddPromotion />} />}

          {/* try to find a way to protect this route if stepCompleted greater than 10 then this route will have to be disabled */}
          {subModuleRoles?.["Business Set Up"]?.["Business Home"]?.View && <Route path="wellnezza/*" element={<Wellnezza />} />}

          {mainModuleRoles?.Publish?.View && <Route path="publish" element={<Publish />} />}
          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/addService/*" element={<Publish />} />}
          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/Edit-service/*" element={<Publish />} />}

          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/add-bundle/*" element={<Publish />} />}
          {subModuleRoles?.['Services']?.Services?.View && <Route path="publish/edit-bundle/*" element={<Publish />} />}

          {subModuleRoles?.['Teams']?.Team?.View && <Route path="publish/addTeams/*" element={<Publish />} />}
          {subModuleRoles?.['Teams']?.Team?.View && <Route path="publish/edit-team/*" element={<Publish />} />}

          {/* sub modules  */}

          {/* services sub menu  */}

          {/* {subModuleRoles?.['Services']?.['Team Title']?.View && <Route path="team-title" element={<TeamTitle type="service"/>} />} */}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services" element={<AllServices />} />}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/addService/*" element={<AllServices />} />}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/Edit-service/*" element={<AllServices />} />}

          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/add-bundle/*" element={<AllServices />} />}
          {(subModuleRoles?.['Services']?.Services?.View || subModuleRoles?.['Services']?.['Service Category']?.View || subModuleRoles?.['Services']?.Bundles.View) && <Route path="services/edit-bundle/*" element={<AllServices />} />}

          {subModuleRoles?.['Services']?.['Assign Branch']?.View && <Route path="assign-services-to-branches" element={<AssignServicesToBusiness />} />}

          {/* Teams sub menu  */}
          {/* {subModuleRoles?.['Teams']?.['Teams']?.View && <Route path="new-teams" element={<TeamTitle type="team"/>} />} */}

          {(subModuleRoles?.['Teams']?.Team?.View || subModuleRoles?.['Teams']?.['Team Title']?.View) && <Route path="new-teams" element={<TeamTitle />} />}
          {(subModuleRoles?.['Teams']?.Team?.View || subModuleRoles?.['Teams']?.['Team Title']?.View) && <Route path="new-teams/addTeams/*" element={<TeamTitle />} />}
          {(subModuleRoles?.['Teams']?.Team?.View || subModuleRoles?.['Teams']?.['Team Title']?.View) && <Route path="new-teams/edit-team/*" element={<TeamTitle />} />}

          {subModuleRoles?.['Teams']?.['Assign Services']?.View && <Route path="assign-services-to-teams" element={<AssignServicesToTeam />} />}
          {subModuleRoles?.['Teams']?.['WN APP Access']?.View && <Route path="roles-list" element={<RolesList />} />}


          {/* Business sub menu  */}
          {subModuleRoles?.['Business Set Up']?.['Business Home']?.View && <Route path="business-setting/basic/*" element={<BusinessSeting />} />}

          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch" element={<Branch />} />}
          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch/add-branch/*" element={<AddBranch />} />}
          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch/edit-branch/*" element={<AddBranch />} />}
          {subModuleRoles?.['Business Set Up']?.['Branch/Location']?.View && <Route path="branch/view-branch" element={<ViewBranch />} />}

          {subModuleRoles?.['Business Set Up']?.['User Access']?.View && <Route path="roll-management" element={<RollManagement />} />}

          {/* {subModuleRoles?.['Business Set Up']?.Packages?.View && <Route path="packages" element={<Packages />} />}
          <Route path="packages/addPackage/*" element={<EditPackage />} />
          <Route path="packages/edit-package/*" element={<EditPackage />} />
          <Route path="packages/view-package" element={<ViewPackage />} />

          {subModuleRoles?.['Business Set Up']?.Memberships?.View && <Route path="membership" element={<MemberShip />} />}
          <Route path="membership/add-membership/*" element={<AddMemberShip />} />
          <Route path="membership/edit-membership/*" element={<AddMemberShip />} />
          <Route path="membership/view-membership" element={<ViewMemberShip />} /> */}


          {/* {subModuleRoles?.['Business Set Up']?.Sales?.View && <Route path="sales" element={<Sales />} />} */}

          {/* <Route path="amenities/addAmenities" element={<AddAmenities />} />
          <Route path="amenities/edit-amenity" element={<EditAmenity />} />
          <Route path="amenities/view-amenity" element={<ViewAmenity />} /> */}




          {/*---------------- Branch Routes -------------------------*/}

          {subModuleRoles?.['Branch Set Up']?.['Branch Home']?.View && <Route path="branch-home" element={<BranchHome />} />}

          {/* <Route path="branch-teams/addTeams/*" element={<AddBranchTeams />} /> */}
          {subModuleRoles?.['Branch Set Up']?.Team?.View && <Route path="branch-teams" element={<BranchTeams />} />}
          {subModuleRoles?.['Branch Set Up']?.Team?.View && <Route path="branch-teams/addTeams/*" element={<AddBranchTeams />} />}
          <Route path="branch-teams/edit-team/*" element={<AddBranchTeams />} />
          <Route path="branch-teams/view-team" element={<ViewBranchTeams />} />

          {subModuleRoles?.['Branch Set Up']?.Services?.View && <Route path="branch-services" element={<BranchServices />} />}
          {subModuleRoles?.['Branch Set Up']?.Services?.View && <Route path="branch-services/addService/*" element={<AddBranchService />} />}
          <Route path="branch-services/Edit-service/*" element={<AddBranchService />} />
          <Route path="branch-services/view-service/*" element={<ViewBranchService />} />

          {subModuleRoles?.['Branch Set Up']?.Packages?.View && <Route path="branch-packages" element={<BranchPackages />} />}
          {subModuleRoles?.['Branch Set Up']?.Packages?.View && <Route path="branch-packages/addPackage/*" element={<EditBranchPackage />} />}
          <Route path="branch-packages/edit-package/*" element={<EditBranchPackage />} />
          <Route path="branch-packages/view-package" element={<ViewBranchPackage />} />

          {subModuleRoles?.['Branch Set Up']?.Client?.View && <Route path="branch-clients" element={<BranchClients />} />}
          {subModuleRoles?.['Branch Set Up']?.Client?.View && <Route path="branch-clients/addClient" element={<AddBranchClient />} />}
          <Route path="branch-clients/edit-client/*" element={<AddBranchClient />} />
          <Route path="branch-clients/view-client" element={<ViewBranchClient />} />

          {subModuleRoles?.['Branch Set Up']?.Amenities?.View && <Route path="branch-amenities" element={<BranchAmenities />} />}
          {subModuleRoles?.['Branch Set Up']?.Amenities?.View && <Route path="branch-amenities/addAmenities" element={<AddBranchAmenity />} />}
          <Route path="branch-amenities/edit-amenity" element={<EditBranchAmenity />} />
          <Route path="branch-amenities/view-amenity" element={<ViewBranchAmenity />} />


          <Route path="bundles" element={<Bundles />} />
          <Route path="amenities" element={<Amenities />} />
          <Route path="notification" element={<Notification />} />
          <Route path="error-404" element={<Error404 />} />

          {/* <Route path="bundles/addBundles" element={<AddBundles />} />
          <Route path="bundles/edit-bundle" element={<EditBundle />} />
          <Route path="bundles/view-bundle" element={<ViewBundle />} /> */}

          {mainModuleRoles?.Chat?.View && <Route path="chat" element={<ChatBox />} />}
          {/* <Route path="Reports" element={<Reports />} /> */}

          <Route path="branch-membership" element={<BranchMemberShip />} />
          <Route path="branch-membership/add-membership" element={<AddBranchMemberShip />} />
          <Route path="branch-membership/edit-membership" element={<AddBranchMemberShip />} />
          <Route path="branch-membership/view-membership" element={<ViewBranchMemberShip />} />

          <Route path="setting" element={<Setting />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;

const StopRedirection = () => {
  const admin_token = localStorage.getItem("user_token");
  const user_data = localStorage.getItem("user_data");
  let userdata;
  if (user_data) {
    userdata = JSON.parse(user_data);
  }
  let authGaurd = (userdata?.emailVerified && userdata?.stepCompleted >= 5 && admin_token);
  return <>
    {!authGaurd ? (
      <div>
        <Outlet />
      </div>
    ) : (
      <Navigate to="/business"></Navigate>
    )}
  </>
}
