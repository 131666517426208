import React from 'react'
import { Col, Row, Table, Tooltip } from 'react-bootstrap'
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import cx from './index.module.scss';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { teamPerson1 } from '../../../assets/images';
import DataCard from './DataCard';
import { formatDateRangeISO, formatDuration } from '../../../constants/Constants';
import TopTeamList from './TopTeamList';
import TopTeamItem from './TopTeamItem';
import TopServiceList from './TopServiceList';
import TopServiceItem from './TopServiceItem';
import EmptyBookingCard from './EmptyBookingCard';

export default function MonthlyData({ homepageData, loading, bookedMinutes, availableMinutes, totalSales, topServiceList, topTeamList, graphData }: any) {
    const data = graphData?.dateWiseData?.map((item: any) => {
        return (
            { name: formatDateRangeISO(item?.name), SGD: item?.day_appointment }
        )
    })
    const barData = graphData?.dateWiseData?.map((item: any) => {
        return (
            { name: formatDateRangeISO(item?.name), Appointment: item?.day_appointment }
        )
    })
    const totalAmountRatio = homepageData?.percentRatio?.allTotalBookingAmount;
    const availableHour = homepageData?.percentRatio?.freeHours;
    const workedHour = homepageData?.percentRatio?.workedHours;
    const currentDateRangeSalesRevenue = homepageData?.percentRatio?.currentDateRangeSalesRevenue;
    return (
        <>
            <Row>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Total Booking revenue' value={`SGD ${homepageData?.bookingReport?.allTotalBookingAmount || 0}`} statusPercentage={`${Math.abs(totalAmountRatio)}`} growth={totalAmountRatio >= 0} text='Previous Month'/>
                </Col>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Sales Completed' value={`SGD ${totalSales || 0}`} statusPercentage={`${Math.abs(currentDateRangeSalesRevenue)}`} growth={typeof(currentDateRangeSalesRevenue) === 'string' ? !workedHour?.includes('-') : currentDateRangeSalesRevenue >= 0}/>
                </Col>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Available Slot' value={`${formatDuration(availableMinutes)}`} statusPercentage={`${Math.abs(availableHour)}`} growth={typeof(workedHour) === 'string' ? !availableHour?.includes('-') : availableHour >= 0 } text='Previous Month'/>
                </Col>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Booked slot' value={`${formatDuration(bookedMinutes)}`} statusPercentage={`${Math.abs(workedHour)}`} growth={typeof(workedHour) === 'string' ? !workedHour?.includes('-') : workedHour >= 0} text='Previous Month'/>
                </Col>
            </Row>

            <Row>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading}`}>
                            <h3>Recent <span>(Last 30 days)</span></h3>
                            <h2 className={cx.usd}>SGD {graphData?.paymentStates?.totalPayment || '0'}</h2>
                            <p className={cx.appointment}>
                                Appointments: <span>{graphData?.totalItems}</span>
                            </p>
                            <p className={cx.appointment}>
                                Appointments value: <span>SGD {graphData?.paymentStates?.totalPayment || '0'}</span>
                            </p>
                        </div>
                        <div className={`${cx.ChartContainer}`}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" angle={-25} textAnchor="end" interval={0} dy={3} />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="SGD" stroke="#1AB192" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading}`}>
                            <h3>Upcoming appointments <span>(Last 30 days)</span></h3>
                            <h2 className={cx.usd}>{graphData?.totalItems} booked</h2>
                            <p className={cx.appointment}>
                                Appointments: <span>{graphData?.totalItems}</span>
                            </p>
                            <p className={cx.appointment}>
                                Appointments value: <span>SGD {graphData?.paymentStates?.totalPayment || '0'}</span>
                            </p>
                        </div>
                        <div className={`${cx.ChartContainer}`}>
                            <ResponsiveContainer width={"100%"} height={300}>
                                <BarChart data={barData} barSize={20}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" angle={-25} textAnchor="end" interval={0} dy={3} />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Appointment" fill="#B09B28" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading} pb-1`}>
                            <h3>Top Services <span>(Last 30 days)</span></h3>
                        </div>

                        {topServiceList?.length > 0 ? <div className={`${cx.serviceTable}`}>
                            <Table borderless>
                                <thead>
                                    <tr>
                                        <th>Service Name</th>
                                        <th>This Month</th>
                                        {/* <th>Last Month</th> */}
                                    </tr>
                                </thead>
                                <TopServiceList key='month'>
                                    {topServiceList?.map((service: any) => (<TopServiceItem key={service?._id} service={service} />))}
                                </TopServiceList>
                            </Table>
                        </div>
                            : (
                                <div style={{ marginLeft: '28px' }}>
                                    <EmptyBookingCard text='No Service Found' loading={loading} />
                                </div>
                            )}

                    </div>
                </Col>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading} pb-1`}>
                            <h3>Top Team <span>(Last 30 days)</span></h3>
                        </div>

                        {topTeamList?.length > 0 ? <div className={`${cx.teamTable}`}>
                            <Table borderless>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>This Month</th>
                                        <th>Total Sales</th>
                                    </tr>
                                </thead>
                                <TopTeamList key='month'>
                                    {topTeamList?.map((team: any) => (<TopTeamItem key={team?._id} team={team} />))}
                                </TopTeamList>
                            </Table>
                        </div> : (
                            <div style={{ marginLeft: '28px' }}>
                                <EmptyBookingCard text='No Team Found' loading={loading} />
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    )
}
