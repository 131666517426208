import React, { useEffect, useState } from 'react'
import TodayData from './TodayData'
import WeeklyData from './WeeklyData';
import Month from 'react-calendar/dist/cjs/YearView/Month';
import MonthlyData from './MonthlyData';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';
import { convertDecimalTimeTotalMinutes, formatSlashDate } from '../../../constants/Constants';

export default function DashboardRoutes({ dynamicName, range }: { dynamicName: string, range: any }) {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [homepageData, setHomePageData] = useState<any>([]);
  const [availableTeamMember, setAvailableTeamMember] = useState<any>([])
  const [topTeamList, setTopTeamList] = useState<any>([])
  const [topServiceList, setTopServiceList] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false);

  const dateType = `${dynamicName?.[0]?.toLocaleUpperCase()}${dynamicName?.slice(1)}`;
  
  async function getHomePageData() {
    let startDate = formatSlashDate(new Date());
    let endDate = formatSlashDate(new Date());

    if (dynamicName === 'week') {
      startDate = formatSlashDate(range?.weekRange?.startOfWeek)
      endDate = formatSlashDate(range?.weekRange?.endOfWeek)
    }
    if (dynamicName === 'month') {
      startDate = formatSlashDate(range?.monthRange?.startOfMonth)
      endDate = formatSlashDate(range?.monthRange?.endOfMonth)
    }
    setLoading(true)
    const response = await httpRequest(`getdashboard/${businessData?._id}/${branchId}?startDate=${startDate}&endDate=${endDate}&dateType=${dateType}`, 'GET', null, 'json');
    if (response?.status) {
      setHomePageData(response)
    }
    setLoading(false)
  }

  async function getTeamAvailablity() {
    let startDate = formatSlashDate(new Date());
    let endDate = formatSlashDate(new Date());

    if (dynamicName === 'week') {
      startDate = formatSlashDate(range?.weekRange?.startOfWeek)
      endDate = formatSlashDate(range?.weekRange?.endOfWeek)
    }
    if (dynamicName === 'month') {
      startDate = formatSlashDate(range?.monthRange?.startOfMonth)
      endDate = formatSlashDate(range?.monthRange?.endOfMonth)
    }
    const response = await httpRequest(`getdashboardteamavailibility/${businessData?._id}/${branchId}?startDate=${startDate}&endDate=${endDate}`, 'GET', null, 'json');
    if (response?.status) {
      setAvailableTeamMember(response?.assigned)
    }
  }
  async function getGraphData() {
    let startDate = formatSlashDate(new Date());
    let endDate = formatSlashDate(new Date());

    if (dynamicName === 'week') {
      startDate = formatSlashDate(range?.weekRange?.startOfWeek)
      endDate = formatSlashDate(range?.weekRange?.endOfWeek)
    }
    if (dynamicName === 'month') {
      startDate = formatSlashDate(range?.monthRange?.startOfMonth)
      endDate = formatSlashDate(range?.monthRange?.endOfMonth)
    }
    
    const response = await httpRequest(`getdashboarddaterangebooking/${businessData?._id}/${branchId}?startDate=${startDate}&endDate=${endDate}&dateType=${dateType}`, 'GET', null, 'json');
    if (response?.status) {
      setGraphData(response)
    }
  }
  const isEmpty = (obj:any) => Object.keys(obj || {}).length === 0;

  async function getTopServices() {
    let startDate = formatSlashDate(new Date());
    let endDate = formatSlashDate(new Date());

    if (dynamicName === 'week') {
      startDate = formatSlashDate(range?.weekRange?.startOfWeek)
      endDate = formatSlashDate(range?.weekRange?.endOfWeek)
    }
    if (dynamicName === 'month') {
      startDate = formatSlashDate(range?.monthRange?.startOfMonth)
      endDate = formatSlashDate(range?.monthRange?.endOfMonth)
    }
    const response = await httpRequest(`getdashboardtopservices/${businessData?._id}/${branchId}?startDate=${startDate}&endDate=${endDate}`, 'GET', null, 'json');
    if (response?.status) {
      let services =  !isEmpty(response?.services) ? Object.values(response?.services) : [];
      services?.sort((a:any, b:any) => b?.timesOfUsages - a?.timesOfUsages)
      setTopServiceList(services)
    }
  }
  async function getTopTeam() {
    let startDate = formatSlashDate(new Date());
    let endDate = formatSlashDate(new Date());

    if (dynamicName === 'week') {
      startDate = formatSlashDate(range?.weekRange?.startOfWeek)
      endDate = formatSlashDate(range?.weekRange?.endOfWeek)
    }
    if (dynamicName === 'month') {
      startDate = formatSlashDate(range?.monthRange?.startOfMonth)
      endDate = formatSlashDate(range?.monthRange?.endOfMonth)
    }
    const response = await httpRequest(`getdashboardtopteam/${businessData?._id}/${branchId}?startDate=${startDate}&endDate=${endDate}`, 'GET', null, 'json');
    if (response?.status) {
      let teams = !isEmpty(response?.teamMember) ? Object.values(response?.teamMember) : []
      teams.sort((a:any, b:any) => b?.timesOfUsages - a?.timesOfUsages)
      setTopTeamList(teams)
    }
  }
  useEffect(() => {
    if (businessData && branchId && dynamicName) {
      getHomePageData()
      getTeamAvailablity();
      

      if (dynamicName !== 'day') {
        getTopTeam();
        getTopServices();
        getGraphData()
      }
    }
  }, [businessData, branchId, dynamicName]);

  const bookedMinutes = convertDecimalTimeTotalMinutes(homepageData?.count?.workedHours)
  const availableMinutes = (homepageData?.count?.totalHours * 60) - bookedMinutes;
  const totalSales = homepageData?.count?.currentDateRangeSalesRevenue

  return (
    <>
      {dynamicName === 'today' && <TodayData
        homepageData={homepageData}
        loading={loading}
        availableTeamMember={availableTeamMember}
        bookedMinutes={bookedMinutes}
        availableMinutes={availableMinutes}
        totalSales={totalSales}
      />}
      {dynamicName === 'week' && <WeeklyData
        homepageData={homepageData}
        loading={loading}
        bookedMinutes={bookedMinutes}
        availableMinutes={availableMinutes}
        totalSales={totalSales}
        topServiceList={topServiceList}
        topTeamList={topTeamList}
        graphData={graphData}
      />
      }
      {dynamicName === 'month' && <MonthlyData
        homepageData={homepageData}
        loading={loading}
        bookedMinutes={bookedMinutes}
        availableMinutes={availableMinutes}
        totalSales={totalSales}
        topServiceList={topServiceList}
        topTeamList={topTeamList}
        graphData={graphData}
      />
      }
    </>
  )
}
