import cx from './loader.module.scss';
export default function Loader() {
    return (
        <div className={cx.loader}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}