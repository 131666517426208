import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { list, plusWhite } from "../../../assets/images";
import NewBookingModal from "../../../components/Business/Modals/BookingModal";
import AppointmentModal from "../../../components/Business/Modals/AppointmentModal";
import CustomarModal from "../../../components/Business/Modals/CustomerModal";
import MarkCompletedModal from "../../../components/Business/Modals/MarkCompleteModal";
import { useDispatch, useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import MyCalendar from "./Scheduler";
import ListView from "./ListView";
import BookingSidebar from "./BookingSidebar";
import AddServiceSideBar from "./AddServiceSidebar";
import AddClientBar from "./AddClientBar";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { changeCurrentBranchData } from "../../../redux_toolkit/reducer/branchReducer";
import { convertTo24Hour, formatSlashDate } from "../../../constants/Constants";
import { TbReload } from "react-icons/tb";
import { Col } from "react-bootstrap";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const bookingTypeList = ['Open', 'Completed', 'Cancelled', 'No Show'];
// const todayFormated = new Date().toISOString().split('T')[0];

const todayFormated = formatSlashDate(new Date())


const Booking = () => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);

  let { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_data") || "{}");
    if (userInfo?.userType === "teamMember") {
      setUserData(userInfo);
    }
  }, [])

  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>([])
  const [showSidebar, setShowSidebar] = useState(false);
  const [showServiceBar, setShowServiceBar] = useState(false);
  const [showClientBar, setShowClientBar] = useState(false);
  const [serviceList, setServiceList] = useState<any>([]);
  const [bundleList, setBundleList] = useState<any>([]);

  const [selectedService, setSelectedService] = useState<any>([]);
  const [selectedCalendarView, setSelectedCalendarView] = useState<string>('day');

  const initialState: any = { date: todayFormated, view: 'day', team: "" }
  const [selectedViewDateTeam, setSelectedViewDateTeam] = useState<any>(initialState);

  const selectedViewDateTeamRef = useRef(selectedViewDateTeam);

  useEffect(() => {
    selectedViewDateTeamRef.current = selectedViewDateTeam;
  }, [selectedViewDateTeam]);

  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [listView, setListView] = useState(false);
  const [activeButton, setActiveButton] = useState("Open");
  const [bookingData, setBookingData] = useState<any>([]);
  const [listData, setListData] = useState<any>([]);
  const [branchTeam, setBranchTeam] = useState<any>([]);
  const [selectDate, setSelectDate] = useState(todayFormated);

  const [serviceLoading, setServiceLoading] = useState(false);
  const [bundleLoading, setBundleLoading] = useState(false);

  const [serviceType, setServiceType] = useState<any>("service");
  const [slotDateTime, setSlotDateTime] = useState(null);

  const [selectedBundle, setSelectedBundle] = useState<any>([]);

  const [showBooking, setShowBooking] = useState(false);
  const handleBookingClose = () => setShowBooking(false);
  const handleBookingShow = () => setShowBooking(true);

  const [showAppointment, setShowAppointment] = useState(false);
  const handleAppointmentClose = () => setShowAppointment(false);

  const [showCustomer, setShowCustomer] = useState(false);
  const handleCustomerClose = () => setShowCustomer(false);

  const [showComplete, setShowComplete] = useState(false);
  const handleCompleteClose = () => setShowComplete(false);

  const location = useLocation();
  const dateParam = location.state && location.state.date;

  const [clientLoading, setClientLoading] = useState<any>(false)

  const dispatch = useDispatch();


  const canViewOwnBooking = mainModuleRoles?.Bookings?.ViewOwnBookings;
  const canViewAllBooking = mainModuleRoles?.Bookings?.ViewAllBookings;

  const canCreateOwnBooking = mainModuleRoles?.Bookings?.CreateOwnAppointment;
  const canCreateAllBooking = mainModuleRoles?.Bookings?.CreateAllAppointments;

  const canChangeOwnBooking = mainModuleRoles?.Bookings?.ChangeOwnBooking;
  const canChangeAllBookings = mainModuleRoles?.Bookings?.ChangeAllBookings;



  const roleObj = {
    canViewOwnBooking,
    canViewAllBooking,
    canCreateOwnBooking,
    canCreateAllBooking,
    canChangeOwnBooking,
    canChangeAllBookings,
    userData,
    singleTeamCreateCheck: !canCreateAllBooking && canCreateOwnBooking && userData !== null,
    singleTeamChangeCheck: !canChangeAllBookings && canChangeOwnBooking && userData !== null,
    singleTeamViewCheck: !canViewAllBooking && canViewOwnBooking && userData !== null,
    type: userData?.userType
  }

  const branchTeamList: any = !canViewAllBooking && canViewOwnBooking && userData !== null ? branchTeam?.filter((item: any) => item?.id === userData?._id) : branchTeam

  useEffect(() => {
    if (dateParam) {
      const inputDate = new Date(location.state.date);
      const formattedDate = moment(inputDate).format('YYYY-MM-DD');
      setSelectDate(formattedDate)
      // setSelectedTeam(location.state.temMemberId)
      setSelectedViewDateTeam({ date: formattedDate, view: 'day', team: location.state.temMemberId })
    }
  }, [dateParam])


  useEffect(() => {
    if (businessData) {
      if (showSidebar) {
        getClient();
      }
    }
  }, [businessData, showSidebar]);

  useEffect(() => {
    if (branchId) {
      setSelectedViewDateTeam(initialState);
      getTeam();
    }
  }, [branchId]);

  useEffect(() => {
    if (branchId) {
      getTeamMembersBooking();
      getBranches(branchId)
    }
  }, [selectedViewDateTeam,]);


  useEffect(() => {
    if (branchId) {
      if (listView) {
        getBookings();
        getBranches(branchId)
      }
    }
  }, [activeButton, branchId, selectDate, selectedViewDateTeam, selectedCalendarView, listView]);

  useEffect(() => {
    setActiveButton("Open")
  }, [listView])

  /////////////////////////////////// booking list api's ////////////////////////////////// 


  const getTeamMembersBooking = async () => {
    setLoading(true);
    getBookings()


    let teamId: any = selectedViewDateTeamRef?.current?.team || "";
    if (selectedViewDateTeamRef?.current?.team && branchTeamList?.some((item: any) => item?.id === selectedViewDateTeamRef?.current?.team)) {
      teamId = selectedViewDateTeamRef?.current?.team;
    }
    let start = selectedViewDateTeam?.date;
    let end = selectedViewDateTeam?.date;

    if (selectedViewDateTeam.view === 'week') {
      if (selectedViewDateTeamRef?.current?.team) {
        teamId = selectedViewDateTeamRef?.current?.team
      } else {
        teamId = branchTeamList[0]?.id
      }
      const { weekStartDate, weekEndDate } = getWeekStartAndEndDates(selectedViewDateTeam?.date);
      start = formatSlashDate(weekStartDate)
      end = formatSlashDate(weekEndDate)
    }

    if (userData && !canViewAllBooking && canViewOwnBooking) {
      teamId = branchTeamList?.find((item: any) => item?.id === userData?._id)?.id
    }

    let path = ""
    if (teamId) {
      path = `getTeamMembersBooking?branchId=${branchId}&businessId=${businessData?._id}&startDate=${start}&endDate=${end}&teamMemberId=${teamId}`
    } else {
      path = `getTeamMembersBooking?branchId=${branchId}&businessId=${businessData?._id}&startDate=${start}&endDate=${end}`
    }
    let res = await httpRequest(path, "get", null, "json");
    if (res.status) {
      setBookingData(res);
    }

    setLoading(false);
  };


  const getBookings = async () => {
    let start = selectDate;
    let end = selectDate;

    let teamId: any = selectedViewDateTeamRef?.current?.team || "";
    if (selectedViewDateTeamRef?.current?.team && branchTeamList?.some((item: any) => item?.id === selectedViewDateTeamRef?.current?.team)) {
      teamId = selectedViewDateTeamRef?.current?.team;
    }

    if (selectedCalendarView === 'week') {
      if (selectedViewDateTeamRef?.current?.team) {
        teamId = selectedViewDateTeamRef?.current?.team
      } else {
        teamId = branchTeamList[0]?.id
      }
      const { weekStartDate, weekEndDate } = getWeekStartAndEndDates(selectDate);
      start = formatSlashDate(weekStartDate)
      end = formatSlashDate(weekEndDate)
    }


    if (userData && !canViewAllBooking && canViewOwnBooking) {
      teamId = branchTeamList?.find((item: any) => item?.id === userData?._id)?.id
    }

    let path = "";
    if (teamId) {
      path = `getBooking?branchId=${branchId}&businessId=${businessData?._id}&desiredStatus=${typeof activeButton === "string" ? activeButton === "Open" ? "Upcoming" : activeButton : ""}&startDate=${start}&endDate=${end}&teamMemberId=${teamId}`
    } else {
      path = `getBooking?branchId=${branchId}&businessId=${businessData?._id}&desiredStatus=${typeof activeButton === "string" ? activeButton === "Open" ? "Upcoming" : activeButton : ""}&startDate=${start}&endDate=${end}`
    }

    let res = await httpRequest(path, "get", null, "json");
    if (res.status) {
      setListData(res?.data);
    }
  };


  ////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////// branch data api's //////////////////////////////////

  const getBranches = async (id: any) => {
    let res = await httpRequest(
      `getBranch?_id=${branchId}`,
      "get",
      null,
      "json"
    );
    if (res.status) {
      let branchlist = res?.data[0];
      dispatch(changeCurrentBranchData({ currentBranch: branchlist }));
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////// cleint and all teams api;s //////////////////////////

  const getClient = async () => {
    setClientLoading(true)
    let res = await httpRequest(`getClient?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setClientList(res.data);
    }
    setClientLoading(false);
  };

  const getTeam = async () => {
    let res = await httpRequest(`getAllTeamMembers?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      const filterTeam = res?.data?.filter((team: any) => team?.Role?.length > 0 && team?.Role?.some((item: any) => {
        if (item?.branch?._id === branchId) {
          return item?.role?.some((roleItem: any) => roleItem?.customerService === true)
        }
      }))
      let mapBranchTeam = filterTeam?.map((item: any) => {
        return ({ name: item?.firstName + ' ' + item.lastName, id: item._id })
      });
      setBranchTeam(mapBranchTeam.reverse());
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////// toolbar for calendar and listview ////////////////

  const dateHandler = (e: any) => {
    const date = e.target.value;
    setSelectDate(date)
  }

  const changeDateByArrow = (type: string) => {
    let existTime = new Date(selectDate)?.getTime()
    if (type === "dec") {
      existTime -= 86400000;
    } else {
      existTime += 86400000;
    }
    const dateofTime = new Date(existTime);

    let formatDate = formatSlashDate(dateofTime)
    setSelectDate(formatDate);
  }

  const handleToolbarChange = useCallback((type: string, data: any) => {
    if (type === "dataType") {
      setActiveButton(data);
    }
    if (type === 'listview') {
      setListView(true);
    } else if (type === 'createNew') {
      handleBookingShow();
    } else if (type === 'navigate') {
      let navigatedDate = '';
      if (data.view === 'week') {
        navigatedDate = addOrSubtractOneWeek(data.date, data.to);
      } else {
        navigatedDate = addOrSubtractDays(data.date, data.to);
        setSelectDate(navigatedDate);
      }
      setSelectedViewDateTeam((prev: any) => ({ ...prev, date: navigatedDate }));
    } else if (type === 'navigateDate') {
      setSelectedViewDateTeam((prev: any) => ({ ...prev, date: data.date }));
    } else if (type === 'changeView') {
      let navigatedDate = formatSlashDate(data?.date) || data.date.toISOString().split('T')[0];
      if (data.to === 'week') {
        if (!selectedViewDateTeamRef.current?.team) {
          setSelectedViewDateTeam((prev: any) => ({
            ...prev,
            date: navigatedDate,
            view: data.to,
            // team: branchTeamList[0]?.id,
          }));
        } else {

          setSelectedViewDateTeam((prev: any) => ({
            ...prev,
            date: navigatedDate,
            view: data.to,
            team: selectedViewDateTeamRef.current?.team,
          }));
        }
      } else {
        setSelectedViewDateTeam((prev: any) => ({ ...prev, date: navigatedDate, view: data.to }));
      }
    } else if (type === 'changeTeam') {
      if (data === '') {
        setSelectedViewDateTeam((prev: any) => ({ ...prev, team: '', view: 'day' }));
      } else {
        setSelectedViewDateTeam((prev: any) => ({ ...prev, team: data }));
      }
    }


  }, [selectedViewDateTeam]);


  function handleCalendarViewChange(val: string) {
    setSelectedCalendarView(val);
    if (val === 'day') {
      setSelectedTeam('');
    } else {
      setSelectedTeam(branchTeamList[0]?.id);
    }
  }

  // function handleTeamChange(val: string) {
  //   setSelectedTeam(val);
  //   if (val === '' && selectedCalendarView !== 'day') {
  //     setSelectedCalendarView('day');
  //   }
  // }

  function handleTeamChange(value: string) {
    if (value === '' && selectedViewDateTeam === 'week') {
      handleToolbarChange('changeTeam', { to: "day", date: selectedViewDateTeam?.date });
    } else {
      handleToolbarChange('changeTeam', value);
    }
  }

  function handleDateSelector(date: string) {
    setSelectDate(date)
  }

  ///////////////////////////////////////////////////////////////////////////////////////

  function getDayName(dateString: string) {
    // Create a Date object from the date string
    const dateObject = new Date(dateString);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject?.getDay();

    // Define an array of day names
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the day name using the day of the week
    const dayName = days[dayOfWeek];
    return dayName
  }


  function handleServiceSelect(type: string) {
    setServiceType(type);
    setShowServiceBar(true);
  }

  function handleTeamBooking(startDate: any, events: any) {
    if (showSidebar) {
      return
    }

    if (!roleObj?.canCreateOwnBooking && !roleObj?.canCreateAllBooking && roleObj?.userData !== null) {
      return
    }


    const selectedDateInMm = new Date(startDate)?.getTime();
    const currDateInMm = new Date()?.getTime();

    if (selectedDateInMm >= currDateInMm) {
      const slotData: any = {
        start: startDate,
        resourceId: null,
      }
      setSlotDateTime(slotData);
      setShowSidebar(true)
    }
  }

  function handleCreateSlotEvent(slotData: any, data: any) {
    if (showSidebar) {
      return
    }
    const selectedDateInMm = new Date(slotData?.slots[0])?.getTime();
    const currDateInMm = new Date()?.getTime();
    if (slotData?.resourceId?.length > 4 && selectedDateInMm >= currDateInMm) {
      setSlotDateTime({ ...slotData, availableSlot: bookingData?.availSlots });
      setShowSidebar(true)
    }
  }


  return (
    <>
      <section className={`${st.pageWrapper} ${cx.wrapper}`} style={{ padding: '10px 8px' }}>
        {/* <Col md={12}>
          <div className="d-flex justify-content-end gap-3">
            <div className={`${cx.reloadBooking_btn}`}>
              <button className="btn" onClick={() => handleReload()}>
                <TbReload />
              </button>
            </div>
            <div className="text-end">
              <HelpButon number={2} type={"help"} className={"btn"} />
            </div>
          </div>
        </Col> */}
        {listView && <div className={`${cx.calendartoolbox} ${cx.listToolBox} d-flex`} style={{ marginTop: '1px' }}>
          <div className={`${cx.firstBox}`}>
            <ul className={cx.view}>
              <li onClick={() => setListView(!listView)}>
                <img src={list} alt="view" />
              </li>
            </ul>
            <div className={`${st.formBox} ${cx.availabilityBox}`}>
              <select value={activeButton} onChange={(e) => handleToolbarChange('dataType', e.target.value)}
                className="form-select">
                {listView ? bookingTypeList?.map((item: any) => {
                  return (
                    <option key={item} value={item}>{item}</option>
                  )
                }) :
                  <option value="">All Bookings</option>}
              </select>
            </div>
            <div className={`${st.formBox} ${cx.availabilityBox}`}>
              <select className={`form-select`} disabled={userData && !canViewAllBooking ? branchTeamList?.length < 2 : branchTeamList?.length === 0} onChange={(e) => handleTeamChange(e.target.value)}
                value={userData && !canViewAllBooking ? userData?._id : selectedViewDateTeam?.team}>
                <option value="" >{branchTeamList?.length === 0 ? "team unavailable" : "All Team"}</option>
                {branchTeamList?.map((item: any) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className={`${cx.right} ${cx.calendarBox}`}>
            <button className={`${cx.todayButton} ${cx.circle} ${selectDate !== todayFormated ? cx.inActive : cx.active}`} onClick={() => setSelectDate(todayFormated)}>Today</button>
            <button onClick={() => changeDateByArrow("dec")}><MdOutlineChevronLeft className={cx.left} /></button>
            <div className={cx.buttonBox}>
              <button className={`${cx.todayButton} ${selectDate !== todayFormated ? cx.inActive : ""}`} >{getDayName(selectDate)}</button>
              {selectedCalendarView === 'day' ? <input type="date" className={`${cx.dateSelector} ${selectDate !== todayFormated ? cx.active : ""} form-control`}
                onChange={dateHandler} value={selectDate !== todayFormated ? selectDate : todayFormated} /> :
                <span>{formatToDDMMYYYY(getWeekStartAndEndDates(selectDate)?.weekStartDate)?.slice(0, 5) + ' To ' + formatToDDMMYYYY(getWeekStartAndEndDates(selectDate)?.weekEndDate)}</span>}
            </div>
            <button onClick={() => changeDateByArrow("inc")}><MdOutlineChevronRight className={cx.left} /></button>
          </div>
          <div className={`${cx.dayBox} ${cx.firstBox} justify-content-end me-0`}>
            <div className={`${st.formBox} ${cx.availabilityBox}`} >
              <select style={{ paddingLeft: '6px' }} className={`form-select`} onChange={(e) => handleCalendarViewChange(e.target.value)}
                value={selectedCalendarView}>
                <option value='day'>Day View</option>
                <option value='week'>Week View</option>
              </select>
            </div>

            {/* <Col lg={6} className="my-2"></Col> */}
            {/* <button
              className={`singleBtn`}
              onClick={handleBookingShow}
            >
              <img src={plusWhite} alt="add" className={cx.addIcon} /> Create
            </button> */}
          </div>
        </div>}

        {!listView ? (
          <MyCalendar bookingData={bookingData}
            key={branchId}
            activeButton={activeButton}
            handleCreateSlotEvent={handleCreateSlotEvent}
            selectedDate={new Date(selectDate)}
            handleDateSelector={handleDateSelector}
            getBooking={getTeamMembersBooking}
            listData={listData}
            setShowSidebar={setShowSidebar}
            handleToolbarChange={handleToolbarChange}
            branchTeamList={branchTeamList}
            selectedViewDateTeam={selectedViewDateTeamRef?.current}
            selectedView={selectedCalendarView}
            dateHandler={dateHandler}
            loading={loading}
            branchId={branchId}
            handleTeamBooking={handleTeamBooking}
            roleObj={roleObj}
          />
        ) : (
          <ListView key={branchId}
            bookingData={listData}
            actionButton={activeButton}
            getBookings={getBookings}
            getBooking={getTeamMembersBooking}
            activeButton={activeButton} />
        )}

        <BookingSidebar
          getClient={getClient}
          setBundleLoading={setBundleLoading}
          bundleLoading={bundleLoading}
          setServiceLoading={setServiceLoading}
          serviceLoading={serviceLoading}
          setServiceList={setServiceList}
          setBundleList={setBundleList}
          handleServiceSelect={handleServiceSelect}
          bundleList={bundleList}
          setSelectedClient={setSelectedClient}
          getTeamMembersBooking={getTeamMembersBooking}
          getBookings={getBookings}
          setSelectedService={setSelectedService} slotData={slotDateTime}
          showSidebar={showSidebar}
          setShowClientBar={setShowClientBar}
          selectedService={selectedService}
          selectedClient={selectedClient}
          setShowSidebar={setShowSidebar}
          setShowServiceBar={setShowServiceBar}
          setSelectedBundle={setSelectedBundle}
          selectedBundle={selectedBundle}
          serviceList={serviceList}
          clientLoading={clientLoading}
          roleObj={roleObj}
        />

        {<AddServiceSideBar service={serviceList} serviceType={serviceType} bundleList={bundleList} setSelectedBundle={setSelectedBundle} selectedBundle={selectedBundle} packageData={setSelectedService} existData={selectedService} setShowServiceBar={setShowServiceBar} showServiceBar={showServiceBar} />}
        <AddClientBar getClient={getClient} setShowClientBar={setShowClientBar} showClientBar={showClientBar} clientList={clientList} setSelectedClient={setSelectedClient} selectedClient={selectedClient} />
      </section>
      {showBooking && <NewBookingModal
        showBooking={showBooking}
        handleBooking={handleBookingClose}
        getBooking={getTeamMembersBooking}
        getBookings={getBookings}
      />}
      {showAppointment && <AppointmentModal
        showAppointment={showAppointment}
        handleAppointmentClose={handleAppointmentClose}
      />}
      {showCustomer && <CustomarModal
        showCustomer={showCustomer}
        handleCustomerClose={handleCustomerClose}
      />}
      {showComplete && <MarkCompletedModal
        showComplete={showComplete}
        handleCompleteClose={handleCompleteClose}
      />}
      {/* <ViewHistoryModal
        showHistory={showHistory}
        handleHistoryClose={handleHistoryClose}
      /> */}
      {/* <Rebook showRebook={true} handleRebookClose={handleRebookClose} /> */}
      {/* <CustomarModal showCustomer={true}/> */}
      {/* <CancelModal handleCancelClose={handleCancelClose} showCancel={true} /> */}
      {/* <RescheduleModal showReschedule={true} handleRescheduleModalClose={handleRescheduleModalClose}/> */}
      {/* <NoShowModal handleNoShowClose={handleNoShowClose} showNoShow={true}/> */}

    </>
  );
};

export default Booking;

function getWeekStartAndEndDates(inputDate: any) {
  const date = new Date(inputDate);
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when the day is Sunday
  const weekStartDate = new Date(date.setDate(diff));
  const weekEndDate = new Date(weekStartDate);
  weekEndDate.setDate(weekStartDate.getDate() + 6);

  return { weekStartDate, weekEndDate };
}

function addOrSubtractDays(date: any, operation: string) {
  let resultDate = date;
  if (operation === 'NEXT') {
    resultDate.setDate(resultDate.getDate() + 1);
  } else if (operation === 'PREV') {
    resultDate.setDate(resultDate.getDate() - 1);
  } else {
    resultDate = new Date();
  }
  return formatSlashDate(resultDate) || resultDate.toISOString().split('T')[0];
}

function addOrSubtractOneWeek(date: any, operation: string) {
  const resultDate = new Date(date);
  if (operation === 'NEXT') {
    resultDate.setDate(resultDate.getDate() + 7);
  } else if (operation === 'PREV') {
    resultDate.setDate(resultDate.getDate() - 7);
  }
  return formatSlashDate(resultDate) || resultDate.toISOString().split('T')[0];
}

function formatToDDMMYYYY(date: any) {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = date.getFullYear();

  return `${dd}-${mm}-${yyyy}`;
}