import React, { Fragment, useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import Table from "react-bootstrap/Table";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { changeAlertCountState } from "../../../redux_toolkit/reducer/AlertMsgReducer";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";

const Services = (props: any) => {

  let { businessData } = useSelector((state: any) => state.shareDataReducer);

  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const dispatch = useDispatch();

  const [selectedBranch, setSelectedBranch] = useState<any>([]);
  const [selectedCat, setSelectedCat] = useState<any>([]);
  const [branchData, setBranchData] = useState([]);
  const [serviceList, setServiceList] = useState<any>([]);
  const [allServicesChecked, setAllServicesChecked] = useState<any>(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>({});
  const [customAllSrvboxes, setCustomAllSrvboxes] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getService();
    }
  }, [businessData]);

  const getService = async () => {
    const res = await httpRequest(`getBusinessPrice?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      let serviceArr = makeCustomServiceArr(res.data);
      setServiceList(serviceArr);
      getBranches();
    }
  };

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status) {
      setBranchData(res.data);
      if (businessData.originalAssignServiceArray) {
        let assingedArr = businessData.originalAssignServiceArray[0];
        setSelectedCheckboxes(assingedArr?.selectedCheckboxes);
        setCustomAllSrvboxes(assingedArr?.customAllSrvboxes);
        setAllServicesChecked(assingedArr?.allServicesChecked);
      } else {
        fillAssignedCheckboxes(res.data);
      }
    }
  }

  function fillAssignedCheckboxes(allBranchData: any) {
    const updatedCheckboxes: any = {};
    const selectAllCheckboxes: any = {};

    allBranchData?.forEach((branch: any) => {
      if (!updatedCheckboxes[branch._id]) {
        updatedCheckboxes[branch._id] = { checked: false };
      }
      serviceList.forEach((serviceCat: any) => {
        if (!updatedCheckboxes[branch._id][serviceCat._id]) {
          updatedCheckboxes[branch._id][serviceCat._id] = { checked: false };
          selectAllCheckboxes[serviceCat._id] = { checked: false };
        }

        serviceCat?.services?.forEach((service: any) => {
          if (!updatedCheckboxes[branch._id][serviceCat._id][service._id]) {
            updatedCheckboxes[branch._id][serviceCat._id][service._id] = { checked: false };
            selectAllCheckboxes[serviceCat._id][service._id] = { checked: false };
          }

          service?.prices?.forEach((price: any) => {
            updatedCheckboxes[branch._id][serviceCat._id][service._id][price._id] = false;
            selectAllCheckboxes[serviceCat._id][service._id][price._id] = false;
          });
        });
      });
    });

    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  }

  const assignServices = async () => {
    setLoading(true);
    let assignedArr = [
      {
        selectedCheckboxes,
        customAllSrvboxes,
        allServicesChecked
      }
    ]

    let pricesToBranchArr = [];
    let RemovedpricesArr = [];

    for (const key1 in selectedCheckboxes) {
      const subObj = selectedCheckboxes[key1];
      if (key1 !== 'checked') {
        for (const key2 in subObj) {
          if (key2 !== 'checked') {
            const subObj2 = subObj[key2];
            for (const key3 in subObj2) {
              if (key3 !== 'checked') {
                const subObj3 = subObj2[key3];
                for (const key4 in subObj3) {
                  if (key4 !== 'checked') {
                    const subObj4 = subObj3[key4];
                    if (subObj4) {
                      pricesToBranchArr.push({ branchId: key1, priceId: key4, BusinessService: key3 });
                    } else {
                      RemovedpricesArr.push({ branchId: key1, priceId: key4, BusinessService: key3 });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const assingedPrices = getPricesBranchWise(pricesToBranchArr);
    const removedPrices = getPricesBranchWise(RemovedpricesArr);
    const assingedPricesArray = Object.values(assingedPrices);
    const removedPricesArray = Object.values(removedPrices);

    let body = {
      assignArray: assingedPricesArray,
      originalAssignServiceArray: assignedArr,
      deleteArray: removedPricesArray
    };

    const res = await httpRequest(`assignBusinessService?businessId=${businessData._id}`, "PATCH", body, "json");
    if (res.status) {
      let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
      if (userdata !== '' && userdata?._id) {
        let bid = '';
        if (userdata?.userType === "teamMember") {
          bid = `businessId=${userdata?.businessId}`;
        } else {
          bid = `id=${userdata?._id}`;
        }
        dispatch(getBusinessData({ id: bid }));
      }
    }
    let alertMsg = res.status ? `Success, ${res.message}` : `Error, ${res.message}`;
    dispatch(changeAlertCountState({ msg: alertMsg }));
    setLoading(false);
  };

  function getPricesBranchWise(arr: any) {
    const bidToPidsMap: any = {};
    arr.forEach((item: any) => {
      const { branchId, priceId, BusinessService } = item;
      if (!bidToPidsMap[branchId]) {
        bidToPidsMap[branchId] = { branchId, priceIds: [], serviceIds: [] };
      }
      bidToPidsMap[branchId].priceIds.push(priceId);
      if (!bidToPidsMap[branchId]?.serviceIds?.includes(BusinessService)) {
        bidToPidsMap[branchId].serviceIds.push(BusinessService);
      }
    });
    return bidToPidsMap;
  }

  function makeCustomServiceArr(allServices: any) {
    const restructuredPricesArray: any = {};
    allServices.forEach((priceObj: any) => {
      const { _id, seasonType, duration, seasonPrice, BusinessService } = priceObj;
      if (BusinessService?.serviceCatagoryId) {
        const { _id: service_id, serviceName: serviceName, ServiceCategory: { _id: serviceCatagoryId, serviceCategoryName: serviceCategoryName } } = BusinessService;

        if (!restructuredPricesArray[serviceCatagoryId]) {
          restructuredPricesArray[serviceCatagoryId] = {
            serviceCategoryName,
            serviceCatagoryId,
            services: {}
          };
        }

        if (!restructuredPricesArray[serviceCatagoryId]?.services[service_id]) {
          restructuredPricesArray[serviceCatagoryId].services[service_id] = {
            serviceName,
            service_id,
            prices: []
          };
        }
        restructuredPricesArray[serviceCatagoryId].services[service_id].prices.push({ seasonType, duration, seasonPrice, _id });
      }
    });
    const finalResult = Object.values(restructuredPricesArray).map((category: any) => ({
      serviceCategoryName: category.serviceCategoryName,
      _id: category.serviceCatagoryId,
      services: Object.values(category.services).map((service: any) => ({
        serviceName: service.serviceName,
        _id: service.service_id,
        prices: service.prices
      }))
    }));
    return finalResult;
  }

  // Handler for the "All Services" checkbox in the thead
  const handleAllServicesCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(isChecked);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    branchData?.forEach((branch: any) => {
      updatedCheckboxes[branch._id] = { checked: isChecked };
      serviceList.forEach((serviceCat: any) => {
        updatedCheckboxes[branch._id][serviceCat._id] = { checked: isChecked };
        selectAllCheckboxes[serviceCat._id] = { checked: isChecked };
        serviceCat?.services?.forEach((service: any) => {
          updatedCheckboxes[branch._id][serviceCat._id][service._id] = { checked: isChecked };
          selectAllCheckboxes[serviceCat._id][service._id] = { checked: isChecked };
          service?.prices?.forEach((price: any) => {
            updatedCheckboxes[branch._id][serviceCat._id][service._id][price._id] = isChecked;
            selectAllCheckboxes[serviceCat._id][service._id][price._id] = isChecked;
          });
        });
      });
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleOneServiceCatToOneBranch = (e: any, branchId: string, ServCatId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    updatedCheckboxes[branchId] = { ...updatedCheckboxes[branchId], checked: false };
    serviceList.forEach((serviceCat: any) => {
      if (ServCatId === serviceCat._id) {
        updatedCheckboxes[branchId][ServCatId] = { ...updatedCheckboxes[branchId][ServCatId], checked: isChecked };
        selectAllCheckboxes[ServCatId] = { ...selectAllCheckboxes[ServCatId], checked: false };

        serviceCat?.services?.forEach((service: any) => {
          updatedCheckboxes[branchId][ServCatId][service._id] = { checked: isChecked };
          selectAllCheckboxes[ServCatId][service._id] = { checked: false };

          service?.prices?.forEach((price: any) => {
            updatedCheckboxes[branchId][ServCatId][service._id][price._id] = isChecked;
            selectAllCheckboxes[ServCatId][service._id][price._id] = false;
          });
        });
      }
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleOneServiceCatToAllBranch = (e: any, ServCatId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };
    branchData?.forEach((branch: any) => {
      updatedCheckboxes[branch._id] = { ...updatedCheckboxes[branch._id], checked: false };
      serviceList.forEach((serviceCat: any) => {
        if (ServCatId === serviceCat._id) {
          updatedCheckboxes[branch._id][ServCatId] = { ...updatedCheckboxes[branch._id][ServCatId], checked: isChecked };
          selectAllCheckboxes[ServCatId] = { ...selectAllCheckboxes[ServCatId], checked: isChecked };

          serviceCat?.services?.forEach((service: any) => {
            updatedCheckboxes[branch._id][ServCatId][service._id] = { checked: isChecked };
            selectAllCheckboxes[ServCatId][service._id] = { ...selectAllCheckboxes[ServCatId][service._id], checked: isChecked };

            service?.prices?.forEach((price: any) => {
              updatedCheckboxes[branch._id][ServCatId][service._id][price._id] = isChecked;
              selectAllCheckboxes[ServCatId][service._id][price._id] = isChecked;
            });
          });
        }
      });
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleAllServicesToOneBranch = (e: any, branchId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    updatedCheckboxes[branchId] = { checked: isChecked };
    serviceList.forEach((serviceCat: any) => {
      updatedCheckboxes[branchId][serviceCat._id] = { checked: isChecked };
      selectAllCheckboxes[serviceCat._id] = { checked: false };

      serviceCat?.services?.forEach((service: any) => {
        updatedCheckboxes[branchId][serviceCat._id][service._id] = { checked: isChecked };
        selectAllCheckboxes[serviceCat._id][service._id] = { checked: false };

        service?.prices?.forEach((price: any) => {
          updatedCheckboxes[branchId][serviceCat._id][service._id][price._id] = isChecked;
          selectAllCheckboxes[serviceCat._id][service._id][price._id] = false;
        });
      });
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleOneServiceToOneBranch = (e: any, branchId: string, ServCatId: string, ServId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    updatedCheckboxes[branchId] = { ...updatedCheckboxes[branchId], checked: false };
    serviceList.forEach((serviceCat: any) => {
      if (ServCatId === serviceCat._id) {
        updatedCheckboxes[branchId][ServCatId] = { ...updatedCheckboxes[branchId][ServCatId], checked: false };
        selectAllCheckboxes[ServCatId] = { ...selectAllCheckboxes[ServCatId], checked: false };
        serviceCat?.services?.forEach((service: any) => {
          if (service._id === ServId) {
            updatedCheckboxes[branchId][ServCatId][service._id] = { checked: isChecked };
            selectAllCheckboxes[ServCatId][service._id] = { checked: false };
            service?.prices?.forEach((price: any) => {
              updatedCheckboxes[branchId][ServCatId][service._id][price._id] = isChecked;
              selectAllCheckboxes[ServCatId][service._id][price._id] = false;
            });
          }
        });
      }
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleOneServiceToAllBranch = (e: any, ServCatId: string, ServId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    branchData?.forEach((branch: any) => {
      updatedCheckboxes[branch._id] = { ...updatedCheckboxes[branch._id], checked: false };
      serviceList.forEach((serviceCat: any) => {
        if (ServCatId === serviceCat._id) {
          updatedCheckboxes[branch._id][ServCatId] = { ...updatedCheckboxes[branch._id][ServCatId], checked: false };
          selectAllCheckboxes[ServCatId] = { ...selectAllCheckboxes[ServCatId], checked: false };
          serviceCat?.services?.forEach((service: any) => {
            if (service._id === ServId) {
              updatedCheckboxes[branch._id][ServCatId][service._id] = { checked: isChecked };
              selectAllCheckboxes[ServCatId][service._id] = { checked: isChecked };
              service?.prices?.forEach((price: any) => {
                updatedCheckboxes[branch._id][ServCatId][service._id][price._id] = isChecked;
                selectAllCheckboxes[ServCatId][service._id][price._id] = isChecked;
              });
            }
          });
        }
      });
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleOnePriceToAllBranch = (e: any, ServCatId: string, ServId: string, priceId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    branchData?.forEach((branch: any) => {
      updatedCheckboxes[branch._id] = { ...updatedCheckboxes[branch._id], checked: false };

      updatedCheckboxes[branch._id][ServCatId] = { ...updatedCheckboxes[branch._id][ServCatId], checked: false };
      selectAllCheckboxes[ServCatId] = { ...selectAllCheckboxes[ServCatId], checked: false };

      updatedCheckboxes[branch._id][ServCatId][ServId] = { ...updatedCheckboxes[branch._id][ServCatId][ServId], checked: false };
      selectAllCheckboxes[ServCatId][ServId] = { ...selectAllCheckboxes[ServCatId][ServId], checked: false };

      updatedCheckboxes[branch._id][ServCatId][ServId][priceId] = isChecked;
      selectAllCheckboxes[ServCatId][ServId][priceId] = isChecked;
    });
    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const handleOnePriceToOneBranch = (e: any, branchId: string, ServCatId: string, ServId: string, priceId: string) => {
    const isChecked = e.target.checked;
    setAllServicesChecked(false);

    const updatedCheckboxes: any = { ...selectedCheckboxes };
    const selectAllCheckboxes: any = { ...customAllSrvboxes };

    updatedCheckboxes[branchId] = { ...updatedCheckboxes[branchId], checked: false };

    updatedCheckboxes[branchId][ServCatId] = { ...updatedCheckboxes[branchId][ServCatId], checked: false };
    selectAllCheckboxes[ServCatId] = { ...updatedCheckboxes[branchId][ServCatId], checked: false };

    updatedCheckboxes[branchId][ServCatId][ServId] = { ...updatedCheckboxes[branchId][ServCatId][ServId], checked: false };
    selectAllCheckboxes[ServCatId][ServId] = { ...updatedCheckboxes[branchId][ServCatId][ServId], checked: false };

    updatedCheckboxes[branchId][ServCatId][ServId][priceId] = isChecked;
    selectAllCheckboxes[ServCatId][ServId][priceId] = false;

    setSelectedCheckboxes(updatedCheckboxes);
    setCustomAllSrvboxes(selectAllCheckboxes);
  };

  const canAssignService = subModuleRoles?.['Services']?.['Assign Branch']?.Edit;

  const [activeService, setActiveService] = useState<any>({});
  const handleActive = (id: any) => {
    let updatedData = { ...activeService };

    updatedData[id] = {
      status: !updatedData[id]?.status
    }
    setActiveService(updatedData)
  }

  const branchHandler = (data: any) => {
    setSelectedBranch(data)
  }
  const catHandler = (data: any) => {
    setSelectedCat(data)
  }


  return (
    <>

      <section className={`${st.pageWrapper}`}>

        <div className={`${st.pageTitle} row mt-1`}>
          <Col md={9}>
            <div className={`${st.titleInfo}`}>
              <h5>Assign Services to Branches</h5>
            </div>
          </Col>
          <Col md={3}>
            <div className="text-end">
              <HelpButon number={43} type={"help"} className={"btn"} />
            </div>
          </Col>
          {/* <div className={`${st.rightInfo}`}></div> */}
        </div>

        <div className={`${st.tableBody} ${cx.tableShadowNone}`}>
          <div className={`${st.tableBodyIn} ${cx.rollTable} mt-2`}>
            <Row className="align-items-end">
              <Col xl={8} lg={9} md={9}>
                <Row style={{ paddingLeft: '19px' }}>
                  <Col md={4}>
                    <div className={`${st.formBox} mt-2`}>
                      <label className="form-label">Branch</label>
                      <MultiSelect
                        value={selectedBranch}
                        onChange={branchHandler}
                        list={branchData?.map((item: any) => ({ label: item?.branchName, value: item?._id }))}
                        style={{ zIndex: "20" }}
                      />
                      
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${st.formBox} mt-2`}>
                      <label className="form-label">Service Category</label>
                      <MultiSelect
                        value={selectedCat}
                        onChange={catHandler}
                        list={serviceList?.map((item: any) => ({ label: item?.serviceCategoryName, value: item?._id }))}
                        style={{ zIndex: "20" }}
                      />
                      {/* <select className="form-select" value={selectedCat} onChange={(e: any) => setSelectedCat(e.target.value)}>
                        <option value="">All</option>
                        {serviceList?.map((catItem: any) => (<option key={catItem?._id} value={catItem?._id}>{catItem?.serviceCategoryName}</option>))}
                      </select> */}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xl={4} lg={3} md={3}>
                <div className={`${st.formBox}`}>

                  <div className={`${st.btnsGroup} justify-content-end`} style={{ paddingRight: '12px' }}>
                    {canAssignService && <button className={`btn ${st.btn4}`} onClick={assignServices} disabled={loading}>
                      {loading ? <LoadingBtn /> : 'Save'}
                    </button>}
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <div className={cx.tableContainer}>
                <Table hover borderless className={cx.table}>
                  <thead>
                    <tr>
                      <th>Services </th>
                      {selectedBranch?.length === 0 && <th>All Branches</th>}
                      {branchData?.map((item: any) => {
                        if (selectedBranch?.length !== 0 && !selectedBranch?.some((branchItem: any) => branchItem?.value === item?._id)) {
                          return
                        }
                        return (
                          <th key={item._id}>{item.branchName}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody className="mb-3">
                    <tr>
                      {selectedCat?.length === 0 && <td style={{ fontWeight: "500", background: "#eff0f5" }}>
                        All Services
                      </td>}
                      {selectedBranch?.length === 0 && selectedCat?.length === 0 && <td style={{ background: "#eff0f5" }}>
                        <label className={`d-block ${st.checkbox} mb-4`}>
                          <input type="checkbox"
                            checked={allServicesChecked}
                            onChange={handleAllServicesCheckboxChange} />
                          <span className={`${st.checkmark}`}></span>
                        </label>
                      </td>}
                      {branchData?.map((item: any) => {
                        if (selectedBranch?.length !== 0 && !selectedBranch?.some((branchItem: any) => branchItem?.value === item?._id)) {
                          return
                        }
                        if (selectedCat?.length !== 0) {
                          return
                        }
                        return (
                          <td key={item._id} style={{ background: "#eff0f5" }}>
                            <label className={`d-block ${st.checkbox} mb-4`}>
                              <input type="checkbox"
                                checked={selectedCheckboxes?.[item?._id]?.checked || allServicesChecked}
                                onChange={(e) => handleAllServicesToOneBranch(e, item._id)} />
                              <span className={`${st.checkmark}`}></span>
                            </label>
                          </td>
                        )
                      })}
                    </tr>
                    {serviceList?.map((item: any, index: number) => {
                      if (selectedCat?.length !== 0 && !selectedCat?.some((catItem: any) => catItem?.value === item?._id)) {
                        return
                      }
                      return (
                        <Fragment key={index}>
                          <tr className={`${cx.add_plusMinus}`} style={{ cursor: 'pointer' }} onClick={() => handleActive(item?._id)}>

                            <td className={`${cx.plusMinus_ShowHide} ${activeService?.[item?._id]?.status ? cx.minus_Show : cx.plus_Show}`} style={{ fontWeight: "500", paddingLeft: "34px" }}>{item.serviceCategoryName}</td>
                            {selectedBranch?.length === 0 && <td>
                              <label className={`d-block ${st.checkbox} mb-4`}>
                                <input type="checkbox"
                                  checked={customAllSrvboxes?.[item?._id]?.checked || allServicesChecked}
                                  onChange={(e) => handleOneServiceCatToAllBranch(e, item._id)} />
                                <span className={`${st.checkmark}`}></span>
                              </label>
                            </td>}
                            {branchData?.map((elem: any) => {
                              if (selectedBranch?.length !== 0 && !selectedBranch?.some((branchItem: any) => branchItem?.value === elem?._id)) {
                                return
                              }
                              return (
                                <td key={elem._id}>
                                  <label className={`d-block ${st.checkbox} mb-4`}>
                                    <input type="checkbox"
                                      checked={selectedCheckboxes?.[elem?._id]?.[item?._id]?.checked || false}
                                      onChange={(e) => handleOneServiceCatToOneBranch(e, elem._id, item._id,)} />
                                    <span className={`${st.checkmark}`}></span>
                                  </label>
                                </td>
                              )
                            })}
                            {/* <span className={`${cx.show_icons}`}>{activeService?.[item?._id]?.status ? "-" : "+"}</span> */}
                          </tr>
                          {activeService?.[item?._id]?.status && item.services?.map((srvs: any) => {
                            return (
                              <Fragment key={srvs._id}>
                                <tr className={cx.nested} style={{ opacity: activeService?.[item?._id]?.status ? "1" : "0" }}>
                                  <td style={{ background: "#eff0f5" }}>{srvs.serviceName}</td>
                                  {selectedBranch?.length === 0 && <td style={{ background: "#eff0f5" }}>
                                    <label className={`d-block ${st.checkbox} mb-4`}>
                                      <input type="checkbox"
                                        checked={customAllSrvboxes?.[item?._id]?.[srvs?._id]?.checked || allServicesChecked}
                                        onChange={(e) => handleOneServiceToAllBranch(e, item._id, srvs?._id)} />
                                      <span className={`${st.checkmark}`}></span>
                                    </label>
                                  </td>}
                                  {branchData?.map((elem: any) => {
                                    if (selectedBranch?.length !== 0 && !selectedBranch?.some((branchItem: any) => branchItem?.value === elem?._id)) {
                                      return
                                    }
                                    return (
                                      <td key={elem._id} style={{ background: "#eff0f5" }}>
                                        <label className={`d-block ${st.checkbox} mb-4`}>
                                          <input type="checkbox"
                                            checked={selectedCheckboxes?.[elem?._id]?.[item?._id]?.[srvs?._id]?.checked || false}
                                            onChange={(e) => handleOneServiceToOneBranch(e, elem._id, item._id, srvs?._id)} />
                                          <span className={`${st.checkmark}`}></span>
                                        </label>
                                      </td>
                                    )
                                  })}
                                </tr>
                                {/* {srvs?.prices?.map((price: any) => {
                                let priceAndType = price.seasonPrice ? `${price.seasonType[0]?.toLocaleUpperCase() + price.seasonType?.slice(1)?.split("P")[0] + " " + "Price"} - $${price.seasonPrice}` : 'Complementary Price : $0';
                                return (
                                  <tr key={price._id} className={cx.inside}>
                                    <td>
                                      {price.duration}: <span className={cx.bold}>{priceAndType}</span>
                                    </td>
                                    <td>
                                      <label className={`d-block ${st.checkbox} mb-4`}>
                                        <input type="checkbox"
                                          checked={customAllSrvboxes?.[item?._id]?.[srvs?._id]?.[price?._id] || allServicesChecked}
                                          onChange={(e) => handleOnePriceToAllBranch(e, item._id, srvs?._id, price?._id)} />
                                        <span className={`${st.checkmark}`}></span>
                                      </label>
                                    </td>
                                    {branchData?.map((elem: any) => {
                                      return (
                                        <td key={elem._id}>
                                          <label className={`d-block ${st.checkbox} mb-4`}>
                                            <input type="checkbox"
                                              checked={selectedCheckboxes?.[elem?._id]?.[item?._id]?.[srvs?._id]?.[price?._id] || false}
                                              onChange={(e) => handleOnePriceToOneBranch(e, elem?._id, item._id, srvs?._id, price?._id)} />
                                            <span className={`${st.checkmark}`}></span>
                                          </label>
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })} */}
                              </Fragment>
                            )
                          })}
                        </Fragment>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Services;