import React from 'react'

export default function TopServiceItem({service}:any) {
    return (
        <tr>
            <td>{service?.serviceName}</td>
            <td>{service?.timesOfUsages}</td>
            {/* <td>350</td> */}
        </tr>
    )
}
