import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import st from '../../../assets/stylesheet/style.module.scss';
import ListGridTable from './DataGrid';
import cx from './index.module.scss';
import { Dropdown, Table } from 'react-bootstrap';
import { call, cancelView, correctIcon, dotThree, editBooking, emailGreen, history, noView, reSchedule, rebookIcon } from '../../../assets/images';
import MarkCompletedModal from '../../../components/Business/Modals/MarkCompleteModal';
import ViewHistoryModal from '../../../components/Business/Modals/ViewHistoryModal';
import CancelModal from '../../../components/Business/Modals/CancelModal';
import NoShowModal from '../../../components/Business/Modals/NoShowModal';
import NewBookingModal from '../../../components/Business/Modals/BookingModal';
import AppointmentModal from '../../../components/Business/Modals/AppointmentModal';
import ListViewRebook from '../../../components/Business/Modals/ListViewRebook';
import moment from 'moment';
import { convertMinutesToTime } from '../../../Utils/Funcs';
import { bundleGrouping, counterWiseBookingServices } from '../../../constants/Constants';
import CustomarModal from '../../../components/Business/Modals/CustomerModal';
import PaymentModal from '../../../components/Business/Modals/PaymentModal';
import { useSelector } from 'react-redux';

export default function ListView(props: any) {
  const { bookingData, actionButton, getBookings, getBooking, activeButton } = props;
  const [reschedule, setReschedule] = useState<Boolean>(false);

  let { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const canChangeOwnBooking = mainModuleRoles?.Bookings?.ChangeOwnBooking;
  const canChangeAllBookings = mainModuleRoles?.Bookings?.ChangeAllBookings;

  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_data") || "{}");
    if (userInfo?.userType === "teamMember") {
      setUserData(userInfo);
    }
  }, [])



  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  //rebook modal
  const [showRebook, setShowRebook] = useState(false);
  const handleRebookClose = () => setShowRebook(false);

  // assign modal
  const [showAppointment, setShowAppointment] = useState<boolean>(false);
  const handleAppointmentClose = () => setShowAppointment(false);

  // paymwnt Modal
  const [showPaymentBooking, setPaymentBookingShow] = useState(false);
  const handlePaymentBookingClose = () => setPaymentBookingShow(false);
  const handlePaymentBookingShow = () => setPaymentBookingShow(true);

  // edit Booking modal
  const [showBooking, setShowBooking] = useState(false);
  const handleBooking = () => setShowBooking(false);
  const handleBookingShow = () => setShowBooking(true);

  // mark complete modal
  const [showComplete, setShowComplete] = useState(false);
  const handleCompleteClose = () => setShowComplete(false);
  const handleCompleteShow = () => setShowComplete(true);

  // view history modal
  const [showHistory, setShowHistory] = useState(false);
  const handleHistoryClose = () => setShowHistory(false);
  const handleHistoryShow = () => setShowHistory(true);

  // cancel Modal
  const [showCancel, setShowCancel] = useState(false);
  const handleCancelClose = () => setShowCancel(false);

  // no Show modal
  const [showNoShow, setNoShow] = useState(false);
  const handleNoShowClose = () => setNoShow(false);

  // customer modal 
  const [showCustomer, setShowCustomer] = useState(false);
  const handleCustomerClose = () => setShowCustomer(false);

  const redirectToChangePage = (client: any, appointment: any) => {
    navigate(`/business/chat`, { state: { client, appointment } });
  };

  function formatDate(date: any) {
    if (date) {
      const dateObj = new Date(date);
      return moment(dateObj).format('DD.MM.YYYY')
    } else {
      return "No Date Available"
    }

  }

  const renderList = bookingData?.map((data: any, i: any) => {
    let teamList: any = [];
    data?.services?.forEach((service: any) => {
      service?.TeamMemberId?.forEach((team: any) => {
        if (teamList?.some((teams: any) => teams?._id === team?._id)) {
          return
        }
        teamList?.push(team)
      })
    })

    // const change: any = canChangeAllBookings || canChangeOwnBooking ? userData !== null ? data?.services?.every((item: any) => item?.TeamMemberId?.length > 0 ? item?.TeamMemberId?.[0]?._id === userData?._id : false) : true : false
    const change: any = canChangeAllBookings

    const totalDuration = data?.services?.reduce((acc: any, val: any) => Number(acc) + Number.parseInt(val?.serviceId?.duration), 0)
    const startArr = data?.salectTime?.split(":");
    const startHours = Number.parseInt(startArr[0]);
    const startMinutes = Number.parseInt(startArr[1]);

    const totalStartMinutes = startHours * 60 + startMinutes
    const totalEndMinutes = totalStartMinutes + totalDuration

    let formattedStartTime = convertMinutesToTime(totalStartMinutes);
    let formattedEndTime = convertMinutesToTime(totalEndMinutes);

    const dateFormat = `${data?.salectDate}T${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}` : startMinutes}:00`;
    let time = new Date(dateFormat);

    const startMilliSecond = time.getTime();
    const bookingDate = startMilliSecond;
    const today = Date.now();

    const bookingId = data ? `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}` : ""

    const bundleGroup: any = bundleGrouping(data?.services);
    const modifiedServices = counterWiseBookingServices(data?.services);
    const bundlewiseServices: any = bundleGroup?.some((item: any) => Object.keys(item)?.[0] === "noBundle") ?
    Object.values(modifiedServices)?.map((service: any) => {
        return (
          <li key={service?.services?.[0]?.serviceId?._id}>{service?.services?.[0]?.serviceDetails?.serviceName}{service?.count > 1 ? ` X ${service?.count}` : ''}</li>
        )
      })
      : bundleGroup?.map((item: any, i: any) => {
        const serviceArr: any = Object.values(item)[0];
        return (
          <li key={i}>
            {Object.keys(item)?.[0]} - ({serviceArr?.map((services: any) => services?.serviceDetails?.serviceName)?.join(", ")})
          </li>
        )
      })

    return (
      <tr key={`${data?._id}${i}`}>
        <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <p className={cx.date}>{`${formatDate(data?.salectDateInFormat)}`}</p>
          <p className={cx.time}>{`${formattedStartTime} - ${formattedEndTime}`}</p>
          <p style={{ fontSize: '14px' }}><strong>Booking Id :</strong> {bookingId}</p>
        </td>
        {/* <td title={bookingId} style={{ width: '100px' }} className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>{formattedBookingId}</td> */}
        <td style={{ width: '100px' }} className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <div className={cx.dotDropdown}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic-6">
                <img src={dotThree} alt="dot" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {actionButton !== "No Show" && change && bookingDate >= today && actionButton !== "Completed" && actionButton !== "Cancelled" && <Dropdown.Item href="#" onClick={() => {
                  handleBookingShow();
                  setData(data)
                }}>
                  <p className={cx.dotDropInside}>
                    <img src={editBooking} alt="edit" /> <span>Edit Booking</span>
                  </p>
                </Dropdown.Item>}

                <Dropdown.Item href="#" onClick={() => {
                  setShowCustomer(true);
                  setData(data)
                }}>
                  <p className={cx.dotDropInside} >
                    <img src={history} alt="details" /> <span>Appointment Details</span>
                  </p>
                </Dropdown.Item>

                <Dropdown.Item href="#" onClick={() => {
                  handleHistoryShow();
                  setData(data)
                }}>
                  <p className={cx.dotDropInside} >
                    <img src={history} alt="history" /> <span>View History</span>
                  </p>
                </Dropdown.Item>

                {change && <Dropdown.Item href="#" onClick={() => {
                  setPaymentBookingShow(true)
                  setData(data)

                }}>
                  <p className={cx.dotDropInside} >
                    <img src={history} alt="payment" /> <span>Payment</span>
                  </p>
                </Dropdown.Item>}


                {actionButton !== "No Show" && change && bookingDate >= today && actionButton !== "Completed" && actionButton !== "Cancelled" && <Dropdown.Item href="#" onClick={() => {

                  handleHistoryShow();
                  setData(data)
                }}>
                  <p className={cx.dotDropInside}>
                    <img src={reSchedule} alt="reschedule" /> <span>Reschedule</span>
                  </p>
                </Dropdown.Item>}

                {actionButton !== "No Show" && change && bookingDate >= today && actionButton !== "Completed" && actionButton !== "Cancelled" && <Dropdown.Item href="#" onClick={() => {
                  setShowCancel(true)
                  setData(data);
                }}>
                  <p className={cx.dotDropInside} >
                    <img src={cancelView} alt="cancel" /> <span>Cancel</span>
                  </p>
                </Dropdown.Item>}
                {actionButton !== "No Show" && change && bookingDate <= today && bookingDate <= today && actionButton !== "Completed" && actionButton !== "Cancelled" && <Dropdown.Item href="#" onClick={() => {
                  setNoShow(true);
                  setData(data);
                }}>
                  <p className={cx.dotDropInside} >
                    <img src={noView} alt="view" /> <span>No Show</span>
                  </p>
                </Dropdown.Item>}
                {actionButton !== "No Show" && change && actionButton !== "Completed" && bookingDate <= today && actionButton !== "Cancelled" && <Dropdown.Item href="#" onClick={() => {
                  setShowComplete(true)
                  setData(data);
                }}>
                  <p className={cx.dotDropInside} >
                    <img src={correctIcon} alt="view" className={cx.correctImage} /> <span>Mark Complete</span>
                  </p>
                </Dropdown.Item>}

                {/* {actionButton !== "Upcoming" && <Dropdown.Item href="#" onClick={() => {
                  setShowRebook(true)
                  setData(data);
                }}>
                  <p className={cx.dotDropInside} >
                    <img src={rebookIcon} alt="view" /> <span>Rebook</span>
                  </p>
                </Dropdown.Item>} */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </td>
        <td style={{ maxWidth: "200px" }} className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <ul>
            {bundlewiseServices}
          </ul>
        </td>
        <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <div className='d-flex gap-1'>
            <p style={{ minWidth: '79px' }}>{data?.bookedBy === "offline" ? `Direct` : "Wellnezza"}</p>
            <p>{data?.bookingStatus === "Upcoming" ? "Open" : data?.bookingStatus}</p>
          </div>
        </td>
        {/* <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <p>{data?.bookingStatus}</p>
        </td> */}
        <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <h2 className={cx.client}>{`${data?.Client?.firstName ? data?.Client?.firstName : "-"} ${data?.Client?.lastName ? data?.Client?.lastName : ""} ${data?.guestname ? `(${data?.guestname})` : ""}`}</h2>
          <div className={cx.action}>
            <div className={cx.imageBox}>
              <a href={`tel:${data?.Client?.mobile ? data?.Client?.mobile : ""}`} title={data?.Client?.mobile ? data?.Client?.mobile : ""}>
                <img src={call} alt="call" />
              </a>
            </div>
            <div className={cx.imageBox}>
              <a href={`mailto:${data?.Client?.email ? data?.Client?.email : ""}`} title={data?.Client?.email ? data?.Client?.email : ""}>
                <img src={emailGreen} alt="email" />
              </a>
            </div>
          </div>
        </td>
        <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          {teamList?.length === 0 ? (
            <h2 className={`${cx.stylist} ${cx.unAssigned}`} style={{ cursor: change ? "pointer" : "auto" }} onClick={() => {
              if (change) {
                setData(data)
                setShowAppointment(true)
              }
            }}>+ Unassigned</h2>
          ) : teamList?.map((team: any) => <h2 className={cx.stylist} key={team?._id}>{`${team?.firstName} ${team?.lastName || ""} ${team?.nickName ? `(${team?.nickName})` : ""}`}</h2>)}

        </td>
        <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <h2 className={cx.stylist}>{`${data?.finalPrice} SGD`}</h2>
        </td>
        <td className={actionButton === "No Show" || teamList?.length === 0 ? cx.noShow : ""}>
          <p>{data?.paymentStatus === "Success" ? "Completed" : "Pending"}</p>
        </td>

      </tr>
    )
  })

  const isBundle: any = data?.services?.some((item: any) => item?.bundleId)
  const bundleGroup = isBundle && data ? bundleGrouping(data?.services) : [];

  return (
    <>
      {showPaymentBooking && <PaymentModal
        handleCustomerClose={handleCustomerClose}
        getBooking={getBooking}
        show={showPaymentBooking}
        handlePaymentBookingClose={handlePaymentBookingClose}
        data={data}
        serviceType={isBundle ? "bundle" : "service"}
        bundleGroup={isBundle ? bundleGroup : null} />}

      {showCustomer && <CustomarModal
        showCustomer={showCustomer}
        viewBookingData={{ event: { updateBooking: data } }}
        getBooking={getBooking}
        handleCustomerClose={handleCustomerClose}
      />}

      {showBooking && <NewBookingModal
        showBooking={showBooking}
        handleBooking={handleBooking}
        data={data}
        getBookings={getBookings}
        getBooking={getBooking}
        reschedule={reschedule}
      />}
      {showRebook && <ListViewRebook data={data} showRebook={showRebook} getBookings={getBookings} handleRebookClose={handleRebookClose} />}
      <MarkCompletedModal
        showComplete={showComplete}
        handleCompleteClose={handleCompleteClose}
        getBookings={getBookings}
        getBooking={getBooking}
        data={data}
        booking={data}
      />
      <ViewHistoryModal
        showHistory={showHistory}
        handleHistoryClose={handleHistoryClose}
        data={data}
      />
      <CancelModal handleCancelClose={handleCancelClose} showCancel={showCancel} data={data} getBookings={getBookings} getBooking={getBooking} />
      <NoShowModal handleNoShowClose={handleNoShowClose} showNoShow={showNoShow} data={data} getBooking={getBooking} getBookings={getBookings} />
      {showAppointment && <AppointmentModal showAppointment={showAppointment} bookingData={data} handleAppointmentClose={handleAppointmentClose} getBookings={getBookings} />}
      <div className={`${st.tableBody}`}>
        <div className={`${st.tableBodyIn} ${cx.rollTable} ${cx.listViewMargin} mt-1`}>
          <div className={cx.tableContainer}>
            <Table hover borderless className={`${cx.table} ${actionButton !== "No Show" ? "table-striped" : ""}`}>
              <thead>
                <tr>
                  <th style={{ minWidth: '181px' }}>Time</th>
                  {/* <th>Booking Id</th> */}
                  <th style={{ width: '100px' }}>Action</th>
                  <th>Services</th>
                  <th>
                    <div className='d-flex'>
                      <div style={{ minWidth: '80px' }}>Channel</div>
                      <div>Booking Status</div>
                    </div>
                  </th>
                  {/* <th>Channel</th>
                  <th>Booking Status</th> */}
                  <th>Client</th>
                  <th>Stylist</th>
                  <th>Price</th>
                  <th>Payment Status</th>
                </tr>
              </thead>
              <tbody>
                {bookingData?.length !== 0 ? renderList : <h2 className={cx.emptyBooking}>{`No ${actionButton} Booking Found`}</h2>}
              </tbody>
            </Table>
          </div>

        </div>
      </div>
    </>
  )
}
