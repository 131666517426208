import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon, editButton } from '../../../assets/images';
import Status from '../../../components/Business/Form/Status';
import { IoSearchSharp } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa';
import TeamList from '../TeamTitle/TeamList';
import { useNavigate } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import { useDispatch, useSelector } from 'react-redux';
import AddNewTeam from '../../../components/Business/OffCanvas/AddNewTeam';
import ToggleNew from '../../../components/Business/Form/ToggleNew';
import { getBusinessData } from '../../../redux_toolkit/reducer/shareDataReducer';

export default function TeamInfo(props: any) {
  const { getTeamMember, teamList, setActiveTab } = props;
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const canCreateTeam = subModuleRoles?.['Teams']?.Team?.Create;
  const canEditTeam = subModuleRoles?.['Teams']?.Team?.Edit;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState<any>('')
  const [branchData, setBranchData] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [showAddNewTeam, setAddNewTeamShow] = useState(false);
  const [published, setPublished] = useState<any>('');
  const handleAddNewTeamClose = () => {
    getTeamMember()
    navigate('/business/publish')
    setAddNewTeamShow(false)
  }

  const getBranch = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setBranchData(res.data);
    }
  };

  useEffect(() => {
    if(businessData){
      getBranch()
    }
  }, [businessData])

  const handleAddNewTeamShow = () => setAddNewTeamShow(true);
  let publishCounter = 0;
  const [allowPublishTeam, setAllowPublishTeam] = useState<boolean>(true)
  const filterTeamList = teamList?.filter((team: any) => {
    if (team?.isPublish) {
      publishCounter++
    }
    const checkBranch = !selectedBranch || team?.Role?.some((role: any) => role?.branch?._id === selectedBranch);
    const queryCheck = !query || `${team?.firstName}${team?.lastName || ''}${team?.nickName || ''}`?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(query?.toLocaleLowerCase()?.replaceAll(' ', ''))
    const publishCheck = published === '' || (published === 'publish' ? team?.isPublish : !team?.isPublish)
    return publishCheck && queryCheck && checkBranch
  })

  async function handleBusinessUpdate(e: any) {
    const checked = e.target.checked
    let formdata = new FormData();
    formdata.append('allowPublishTeamMember', JSON.stringify(checked));
    let res = await httpRequest(`updateBusiness/${businessData?._id}`, "PATCH", formdata, "formdata");
    if (res.status) {
      let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
      if (userdata !== '' && userdata?._id) {
        let bid = '';
        if (userdata?.userType === "teamMember") {
          bid = `businessId=${userdata?.businessId}`;
        } else {
          bid = `id=${userdata?._id}`;
        }
        dispatch(getBusinessData({ id: bid }));
      }
    }
  }

  useEffect(() =>{
    if(businessData){
      setAllowPublishTeam(businessData?.allowPublishTeamMember)
    }
  },[businessData])

  return (
    <>
      <AddNewTeam show={showAddNewTeam} handleAddNewTeamClose={handleAddNewTeamClose} getTeamMember={getTeamMember} />
      <Row>
        <Col md={12}>
          <div className='d-flex gap-3 mb-3 justify-content-end'>
            <div className={`${st.reFormBox} mb-0`} style={{ width: '155px' }}>
              <select className="form-select" value={published} onChange={(e: any) => setPublished(e.target.value)}>
                <option value="">All</option>
                <option value="publish">List on Wellnezza</option>
                <option value="unpublish">Unpublish</option>
              </select>
            </div>
            {/* <div className='text-end'>
              <button className={`${st.addGrey} btn`} onClick={() => setActiveTab('bundles')}>
                Next
              </button>
            </div> */}
          </div>
        </Col>
      </Row>

      {canEditTeam && <Row>
        <Col md={9}>
          <div className='d-flex align-items-center justify-content-center gap-3 mb-5'>
            <label style={{ fontWeight: '500' }}>Show Team Memer Profile on you Wellnezza Page</label>
            <ToggleNew onChange={handleBusinessUpdate} checked={allowPublishTeam} key='team'/>
          </div>
        </Col>
      </Row>}

      <Row className="align-items-center">
        <Col xl={10} lg={12} className="mb-4 pb-2">
          <div className={`${st.publish_pagesHeading}`}>
            <p className='mb-0'>{publishCounter} Team member will be published in Wellnezza</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={9} lg={10} md={11}>
          <Row className="g-3 mb-3">
            <Col md={3}>
              <Row className="g-3">
                <Col md={12}>
                  <div className={`${st.reFormBox} mb-0`}>
                    <select className="form-select" value={selectedBranch} onChange={(e: any) => setSelectedBranch(e.target.value)}>
                      <option value="">Branch</option>
                      {branchData?.map((branch: any) => {
                        return (
                          <option value={branch?._id} key={branch?._id}>{branch?.branchName}</option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={9}>
              <Row className="g-3">
                <Col md={12}>
                  <div className='d-flex gap-3'>
                    <div className={`position-relative ${st.reFormBox} ${st.searchBoxIcon} mb-0 w-100`}>
                      <input type="search" placeholder="Search for team member" className="form-control" value={query} onChange={(e: any) => setQuery(e.target.value)} />
                      <IoSearchSharp />
                    </div>
                    {canCreateTeam && <button className={`btn ${st.addBlue}`} style={{ whiteSpace: 'nowrap' }} onClick={() => {
                      navigate('/business/publish/addTeams/info')
                      handleAddNewTeamShow()
                    }}><FaPlus /> Add Team Member</button>}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {filterTeamList?.map((team: any) => {
              return (
                <TeamList key={team?._id} team={team} type='publish' getTeamMember={getTeamMember} handleAddNewTeamShow={handleAddNewTeamShow} />
              )
            })}
          </Row>
        </Col>
      </Row>
    </>
  )
}
