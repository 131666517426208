import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import cx from '../index.module.scss';
import st from '../../../../assets/stylesheet/style.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { httpRequest } from '../../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { deleteIcon, editButton } from '../../../../assets/images';
import LoadingBtn from '../../Buttons/LoadingBtn';

export default function AccessControl(props: any) {
    const { handleStepsFormData, loading, editTeamData } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [allBranchList, setAllBranchList] = useState([]);
    const [selectedBrachRoles, setSelectedBrachRoles] = useState<any>([]);
    const [customErr, setCustomErr] = useState('');
    const [accessRole, setAccessRole] = useState<any>([])

    const [editRoleIndex, setEditRoleIndex] = useState<number>(-1);
    const [selectedTeamTitles, setSelectedTeamTitles] = useState<any>([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm();

    const {
        register: registerRole,
        handleSubmit: handleSubmitRole,
        formState: { errors: errorsRole },
        setValue: setRoleValue,
        setError,
        control: roleControl,
        clearErrors,
        watch,
        reset: resetRole,
    } = useForm();

    useEffect(() => {
        if (editTeamData) {
            reset();
            let branchArr: any = [];
            // if (!editTeamData.Role[0]?.branch) {
            //   setValue('globalRole', editTeamData.Role[0]?.role?._id);
            //   setValue('accessManager', editTeamData.Role[0]?.accessManager);
            //   setIsGlobal(true);
            // }
            editTeamData.Role?.forEach((elem: any) => {
                let roleArr = elem?.role[0]?._id ? elem?.role.map((item: any) => item._id) : elem?.role;
                branchArr.push({
                    branchName: elem.isGlobal ? 'Global' : elem.branch?.branchName,
                    branch: elem.isGlobal ? 'global' : elem?.branch?._id,
                    role: roleArr,
                    accessManager: elem?.accessManager,
                    accessManagerId: elem?.accessManagerId
                });
            });
            setSelectedBrachRoles(branchArr);

        }
    }, [editTeamData]);

    useEffect(() => {
        if (businessData) {
            getBranches();
            getRoles()
            // getTitle();
        };
    }, [businessData]);


    const selectedBranch = watch("branch");

    const submitHandler = async (data: any) => {
        let finalRoleArr = [];

        if (selectedBrachRoles.length < 1) {
            setCustomErr('addbranch');
            return;
        }
        finalRoleArr = selectedBrachRoles;

        let basicinfo: any = {};
        basicinfo.Role = JSON.stringify(finalRoleArr);
        handleStepsFormData(basicinfo, "step5");

    };

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status) {
            let allBranches = res.data;
            allBranches.reverse();
            setAllBranchList(allBranches);
        }
    }

    const getRoles = async () => {
        let res = await httpRequest(`getSuperRoles`, "get", null, 'json');
        if (res.status) {
            setAccessRole(res?.data)
        }
    }

    const addBranchHandler = (postdata: any) => {
        let accessManager: any = []
        let accessManagerId: any = []
        postdata?.accessManager?.forEach((item: any) => {
            accessManager.push(item?.label)
            accessManagerId.push(item?.value)
        })
        setCustomErr('');
        let newArray = [...selectedBrachRoles]
        const branchIndex = newArray?.findIndex((item: any) => item?.branch === postdata?.branch)
        newArray[branchIndex] = {
            ...newArray?.[branchIndex],
            accessManager,
            accessManagerId,
        }

        setEditRoleIndex(-1);
        setSelectedBrachRoles(newArray);
        setSelectedTeamTitles([]);
        setRoleValue('branch', '');
        clearErrors("role");
        setRoleValue('accessManager', []);
    };


    function handleRemoveBranch(branchId: string) {
        let newSelected: any = [...selectedBrachRoles];
        newSelected = newSelected.map((item: any) => {
            if (item?.branch === branchId) {
                return {
                    ...item,
                    accessManager: [],
                    accessManagerId: [],

                }
            }
            return item
        })

        setSelectedBrachRoles(newSelected);
    }

    function handleEditBranchRole(values: any, id: number) {
        resetRole();
        setRoleValue('branch', values.branch);
        setRoleValue('accessManager', values.accessManager?.map((item: any, i: number) => ({ label: item, value: values.accessManagerId?.[i] })));
        setEditRoleIndex(id);
    }

    const selectedBranchIds = selectedBrachRoles?.filter((item: any) => item?.accessManager?.length > 0).map((item: any) => item.branch);

    const filteredBranchList = allBranchList?.filter((item: any) => selectedBrachRoles?.some((roleItem: any) => roleItem?.branch === item?._id) && !selectedBranchIds?.includes(item?._id) || item._id === watch('branch', ''));
    const filterRole = selectedBrachRoles?.filter((item: any) => item.accessManager?.length > 0)

    const accessRoleValues = accessRole?.map((item: any) => ({ label: item?.role, value: item?._id }));
    return (
        <>
            <div className={`${st.offTab_heading}`}>
                <h6>Access Control</h6>
                <p className={`${st.offcanvas_para}`}>Choose which activity this team member can perform on Wellnezza App.</p>
            </div>
            <Row>
                <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                        <label >Branch</label>
                        <select className='form-select' {...registerRole("branch", {
                            required: true,
                        })}>
                            <option value="">--Select--</option>
                            {/* {(!isGlobalAdded || isGlobalEdit) && <option value="global">Global</option>} */}
                            {filteredBranchList?.map((item: any) => {
                                return (
                                    <option value={item._id} key={item._id}>
                                        {item.branchName}
                                    </option>
                                );
                            })}
                        </select>
                        {errorsRole?.branch?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
                <Col md={5}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Access Manager</label>
                        <Controller
                            name="accessManager"
                            control={roleControl}
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={accessRoleValues}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}

                                    />
                                )
                            }} />
                        {errorsRole?.accessManager?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
                <Col md={2}>
                    <div className={`${st.reFormBox}`}>
                        <label style={{ opacity: '0', display:'block' }}>edf</label>
                        <button className='btn saveGrey' onClick={handleSubmitRole(addBranchHandler)}>
                            {editRoleIndex >= 0 ? 'Edit' : 'Save'}
                        </button>
                        {customErr?.includes('addbranch') && (
                            <p className={"errorMessage"}>Please add atleast one branch</p>
                        )}
                    </div>
                </Col>
            </Row>
            {filterRole?.length > 0 && <Row>
                <Col md={12}>
                    <div className={`${cx.divider}`}></div>
                </Col>
                <Col md={5}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Branch</label>
                    </div>
                </Col>

                <Col md={5}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Access Manager</label>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={cx.action}>
                    </div>
                </Col>
            </Row>}

            {filterRole?.map((item: any, index: any) => {
                return (
                    <div key={index}>
                        <Row>
                            <Col md={5}>
                                <div className={`${st.reFormBox}`}>
                                    <input type="text" title={item?.branchName} disabled className="form-control" value={item.branchName} />
                                </div>
                            </Col>

                            <Col md={5}>
                                <div className={`${st.reFormBox}`}>
                                    <input type="text" title={item?.accessManager} disabled className="form-control" value={item.accessManager} />
                                </div>
                            </Col>
                            <Col md={2}>
                                <ul className={`${st.btnList} align-items-center mb-2`}>
                                    <li
                                        onClick={() => handleEditBranchRole(item, index)}
                                    >
                                        <button> <img src={editButton} alt="edit" /></button>
                                    </li>
                                    <li onClick={() => handleRemoveBranch(item?.branch)}>
                                        <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                )
            })
            }
            <div className={`${cx.offcanvas_footer}`}>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                            <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSubmit(submitHandler)} disabled={loading}>
                                {loading ? (<LoadingBtn />
                                ) : (
                                    'Next'
                                )}
                            </button>
                            {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e) => {
                                e.preventDefault();
                                handleStepsFormData(null, 'cancel')
                            }}>
                                Cancel
                            </button> */}
                        </div>
                    </div>
                </Col>
            </div>
        </>
    )
}
