import React, { useEffect, useState } from 'react';
import cx from './index.module.scss';
import { teamPerson1 } from '../../../assets/images';
import { useSelector } from 'react-redux';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../../Firebase';
import { useNavigate } from 'react-router-dom';

export default function RecentChat() {
    let { branchId, currentBranchData } = useSelector((state: any) => state.BranchReducer);
    const [chats, setChats] = useState<any>([]);
    const navigate = useNavigate()
    
    useEffect(() => {
        if(branchId){
            const unChatSub = onSnapshot(
              collection(db, "userChat", branchId, 'booking'),
              (snapshot: any) => {
                let allMessage:any = []
                snapshot?.forEach((doc: any) => {
                  let messages = doc?.data()?.messages || []
                  if(messages?.length > 0){
                    messages = messages?.map((message:any) => ({...message, client: doc?.data()?.Client}))
                    allMessage.push(...messages)
                  }else{
                    allMessage = []
                  }
                })
                allMessage = allMessage?.sort((a:any, b:any) => b?.date - a?.date)?.filter((chat:any) => !chat?.imgformat)?.slice(0, 3)
                setChats(allMessage)
              }
            );
            return () => {
              unChatSub()
            };
        }
        
      }, [branchId]);

    function handleNavigate () {
        navigate('/business/chat')
    }
    return (
        <div className={`${cx.notification_card}`}>
            <div className={`${cx.notification_header}`}>
                <div className={`${cx.notification_titleCount}`}>
                    <h2>Recent Chat Messages</h2>
                    {/* <span className={`${cx.count}`}>1</span> */}
                </div>
                <div className={`${cx.notification_action}`}>
                    <button onClick={handleNavigate}>View All</button>
                </div>
            </div>

            <ul className={`${cx.notification_item}`}>
                {chats?.map((chat: any) => {
                    let timeStamp: any = chat?.date
                    let timeDate: any;
                    if (timeStamp) {
                      timeDate = new Date(timeStamp?.seconds * 1000 + timeStamp?.nanoseconds / 1000000)
                    }
        
                    const options: any = {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    };
        
                    const formatter = new Intl.DateTimeFormat('en-US', options);
                    const formattedDate = timeDate ? formatter.format(timeDate) : ""
                    const sender = chat?.type === 'user' ? chat?.client?.firstName : currentBranchData?.branchName;
                    const reciever = chat?.type === 'user' ? currentBranchData?.branchName : chat?.client?.firstName
                    return (
                        <li>
                            <div className={`${cx.notification_ImgPara}`}>
                                {chat?.type === 'user' && chat?.client?.image && <img src={chat?.client?.image} alt="avatar" />}
                                {(!chat?.client?.image || chat?.type !== 'user') && <div className={`${cx.avatarAlt}`}>{sender?.[0]?.toLocaleUpperCase()}</div>}
                                <div className={`${cx.paraBox}`}>
                                    <p className={`${cx.paraTop}`}>{sender} <span>on {formattedDate}</span></p>
                                    <p className={`${cx.paraBottom}`}><span>@{reciever}</span> {chat?.text}</p>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
