import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import { FaTelegramPlane } from "react-icons/fa";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { call, callWhite, img1 } from "../../../assets/images";
import { FiCamera, FiSearch } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import { FaUserPlus } from "react-icons/fa";
import { MdCall, MdKeyboardVoice, MdOutlineMoreVert } from "react-icons/md";
import User from "./User";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { auth, db, storage } from "../../../Firebase";
import { doc, setDoc, getDocs, getDoc, onSnapshot, collection, updateDoc } from "firebase/firestore";
import Chats from "./Chats";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import moment from 'moment';
import { ContactDetails } from "../../../components/Business/Modals";

const ChatBox = (props: any) => {
    const [showContactDetails, setContactDetailsShow] = useState(false);
    const handleContactDetailsClose = () => setContactDetailsShow(false);
    const handleContactDetailsShow = () => setContactDetailsShow(true);

    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const [selectedChat, setSelectedChat] = useState<any>(null);

    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [filterMessaeg, setFilterMessaeg] = useState("")
    const [activeStatus, setActiveStatus] = useState<boolean>(false);

    const stringWithoutHyphens = selectedChat?._id;


    useEffect(() => {
        const totalDuration = selectedChat?.bookingData?.services?.reduce((acc: any, val: any) => Number(acc) + Number.parseInt(val?.serviceId?.duration), 0)
        const startArr = selectedChat?.bookingData?.salectTime?.split(":");
        const startHours = Number.parseInt(startArr?.[0]);
        const startMinutes = Number.parseInt(startArr?.[1]);
        const totalStartMinutes = startHours * 60 + startMinutes
        const totalEndMinutes = totalStartMinutes + totalDuration
        let formattedStartTime = convertMinutesToTime(totalStartMinutes);
        let formattedEndTime = convertMinutesToTime(totalEndMinutes);
        setStartTime(formattedStartTime)
        setEndTime(formattedEndTime)
    }, [selectedChat])

    useEffect(() => {
        if (selectedChat && selectedChat?.chatType !== 'admin') {
            const clientId = selectedChat?.chatType === 'generalUser' ? selectedChat?.cuId : selectedChat?.bookingData?.clientUid;
            if(clientId){
                const unSub = onSnapshot(doc(db, "onlineStatus", clientId),
                    (docSnapshot: any) => {
                        if (docSnapshot.exists()) {
                            setActiveStatus(docSnapshot.data()?.status);
                        } else {
                            console.log("Document does not exist.");
                        }
                    },
                    (error: any) => {
                        console.error("Error fetching onlineStatus document:", error);
                    }
                );
    
                return () => {
                    unSub();
                }
            }
        }
        if (selectedChat?.chatType === 'admin') {
            const unSub = onSnapshot(doc(db, "superadminonlineStatus", 'SA'),
                (docSnapshot: any) => {
                    setActiveStatus(docSnapshot.data()?.isOnline)
                },
                (error: any) => {
                    console.error("Error fetching onlineStatus document:", error);
                }
            );

            return () => {
                unSub();
            }
        }

    }, [selectedChat]);

    const changeStatusToOnline = async () => {
        try {
            const res = await getDoc(doc(db, "superadminuser", businessData?._id));
            if (res.exists()) {
                await updateDoc(doc(db, "superadminuser", businessData?._id), {
                    isOnline: true,
                    time: Date.now()
                });
            }
        } catch (err: any) {
            console.log(err, 'errDAtaaaaaa')
        }
        if (businessData?._id) {
            try {
                const res: any = await getDoc(doc(db, "businessOnlineStatus", businessData?._id));
                if (!res.exists()) {
                    await setDoc(doc(db, "businessOnlineStatus", businessData?._id), {
                        status: true,
                        time: Date.now()
                    });
                } else {
                    await updateDoc(doc(db, "businessOnlineStatus", businessData?._id), {
                        status: true,
                        time: Date.now()
                    });
                }
            } catch (err: any) {
                console.log(err, 'errDAtaaaaaa')
            }
        }
    }
    const changeStatusToOffline = async () => {
        try {
            const res = await getDoc(doc(db, "superadminuser", businessData?._id));
            if (res.exists()) {
                try {
                    await updateDoc(doc(db, "superadminuser", businessData?._id), {
                        isOnline: false
                    });
                } catch (err: any) {
                    console.log(err);
                }
            }
        } catch (err: any) {
            console.log(err, 'errordata');
        }

        if (businessData?._id) {
            try {
                const res: any = await getDoc(doc(db, "businessOnlineStatus", businessData?._id));
                
                if (!res.exists()) {
                    await setDoc(doc(db, "businessOnlineStatus", businessData?._id), {
                        status: false,
                        time: Date.now()
                    });
                } else {
                    await updateDoc(doc(db, "businessOnlineStatus", businessData?._id), {
                        status: false,
                        time: Date.now()
                    });
                }
            } catch (err: any) {
                console.log(err, 'errDAtaaaaaa')
            }
        }
    }

    useEffect(() => {
        if (businessData?._id) {
            changeStatusToOnline()
        }

        return () => {
            changeStatusToOffline()
        }

    }, [businessData?._id]);

    const firstNameLetter = selectedChat?.Client?.firstName?.charAt(0).toUpperCase();
    const firstLastNameLetter = selectedChat?.Client?.lastName?.charAt(0).toUpperCase();
    function handleChatSelection(chat: any) {
        if (chat) {
            setSelectedChat(chat)
        }
    }

    const formattedDate = selectedChat?.bookingData?.salectDate ? new Date(selectedChat?.bookingData?.salectDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    }) : ''

    useEffect(() => {
        if(branchId){
            setSelectedChat(null)
        }
    },[branchId])
    return (
        <>
            <section className={`${st.pageWrapper} pb-0 pe-0 ps-1`} style={{ paddingTop: '58px !important' }}>
                <Row className="m-0">
                    <User
                        handleChatSelection={handleChatSelection}
                        selectedChat={selectedChat}
                        key={branchId}
                    />
                    {selectedChat && <Col md={selectedChat?.chatType === 'admin' ? 8 : 5} className={`${cx.detailBox} ${cx.chatBoxHide} p-0 `}>
                        <div className={`${cx.chatbody}`}>
                            <Row className={`${cx.chatHead}`}>
                                <Col md={10}>
                                    <div className={`${cx.userDetails}`}>
                                        {!selectedChat?.Client?.image ? (
                                            <div className={cx.emptyImageBox}>
                                                <span>{selectedChat?.Client?.firstName?.[0]?.toLocaleUpperCase()}{selectedChat?.Client?.lastName?.[0]?.toLocaleUpperCase()}</span>
                                            </div>
                                        ) : <img src={selectedChat?.Client?.image ? selectedChat?.Client?.image : img1} className={`${cx.profileImg}`} />}
                                        <div className={`${cx.chatListBody}`}>
                                            <h5>{`${selectedChat?.Client?.firstName} ${selectedChat?.Client?.lastName}`}</h5>

                                            <p className={`${cx.status}`}>
                                                {/* <span style={{ background: "#15a620" }}></span> */}
                                                {activeStatus ? (
                                                    <span> Online</span>
                                                ) : (
                                                    <span>Offline</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <ul className={`${cx.rightTopList}`}>

                                    </ul>
                                </Col>
                            </Row>
                            {selectedChat && <Chats selectedChat={selectedChat} uid={stringWithoutHyphens}
                                currentUserId={selectedChat?._id}
                                filterMessaeg={filterMessaeg}
                            />}
                        </div>
                    </Col>}
                    {(selectedChat && selectedChat?.chatType !== 'admin') && <Col className={`${cx.detailBox} col p-0 `}>
                        <div className={`${cx.detailBody}`}>
                            <div className={`${cx.heading}`}>
                                <h5>Details</h5>
                            </div>
                            <div className={`${cx.connection}`}>

                                <div className={`${cx.connectionBox}`}>
                                    {selectedChat?.Client?.image ? <div className={`${cx.imageBox}`}>
                                        {/* <span className={`${cx.role}`}>Saloon</span> */}
                                        <img src={selectedChat?.Client?.image ?? ""} className={`${cx.connectImage}`} alt={firstNameLetter} />
                                    </div> : (selectedChat?.profileImage || selectedChat?.image?.[0]) ? (
                                        <img src={selectedChat?.profileImage || selectedChat?.image?.[0]} className={`${cx.connectImage}`} alt={selectedChat?.businessName} />
                                    ) : (
                                        <div className={`${cx.imageLogoBox}`}>
                                            <span>{firstNameLetter || ''}{firstLastNameLetter || ''}</span>
                                        </div>
                                    )}
                                    <h3>{selectedChat?.Client?.firstName} {selectedChat?.Client?.lastName}</h3>
                                    <div className={`${cx.callBox}`} >
                                        <img src={callWhite} alt="call-icon" onClick={handleContactDetailsShow} />
                                    </div>
                                </div>
                            </div>
                            <div className={`${cx.dateHeading}`}>
                                <h5>{selectedChat?.chatType === 'generalUser' ? 'General Chat' : 'Appointment date'}</h5>
                            </div>
                            {selectedChat?.chatType !== 'generalUser' && <div className={`${cx.appointment}`}>
                                <h2> {formattedDate}</h2>
                                <p>{startTime} -{endTime}</p>
                                <div className={`${cx.services}`}>
                                    {selectedChat?.bookingData?.services?.map((item: any) => {
                                        return (
                                            <span> {item?.serviceId?.serviceId?.serviceName} ({item?.TeamMemberId?.[0]?.firstName ? `${item.TeamMemberId[0].firstName} ${item.TeamMemberId[0].lastName}` : 'Any professional'})</span>
                                        )
                                    })}
                                </div>
                            </div>}
                        </div>
                        {selectedChat?.chatType !== 'generalUser' && <div className={`${cx.price}`}>
                            <span>Price</span>
                            <span className={`${cx.amount}`}>{selectedChat?.bookingData?.finalPrice}USD</span>
                        </div>}
                    </Col>}
                </Row>
            </section>
            {/* </Col> */}

            <ContactDetails show={showContactDetails} clientDetails={selectedChat?.Client} handleContactDetailsClose={handleContactDetailsClose} />

        </>


    );
};

export default ChatBox;
