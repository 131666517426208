import React from 'react'
import { Col, ProgressBar } from 'react-bootstrap';
import cx from './index.module.scss';
import { convertTimetoTotalMinutes, convertToHoursMinutes } from '../../../constants/Constants';

export default function AvailableTeamCard({ team }: any) {
    const totalMinutes = team?.availablity?.split(":")?.[1] ? convertTimetoTotalMinutes(team?.availablity?.split(":")?.[1]) : 0
    const bookedMinutes = team?.booked?.split(":")?.[1] ? convertTimetoTotalMinutes(team?.booked?.split(":")?.[1]) : 0;
    const availableMinutes = totalMinutes - bookedMinutes
    const formattedTotalString = convertToHoursMinutes(totalMinutes)
    const formattedAvailableString = convertToHoursMinutes(availableMinutes)

    return (

        <Col xl={12} lg={6}>
            <div className={`${cx.dashboard_FlexBox} mb-3`}>
                <div className={`${cx.boxLeft}`}>
                    <h4>{`${team?.firstName || ''} ${team?.lastName || ''}`}</h4>
                    <span className={`${cx.availableProgress}`}> Available for <span className={`${cx.availability}`}>{formattedAvailableString}/{formattedTotalString}</span></span>
                </div>
                <div className={`${cx.boxRight}`}>
                    <div className={`${cx.progressBox}`}>
                        <ProgressBar now={bookedMinutes} max={totalMinutes} />
                    </div>
                </div>
            </div>
        </Col>

    )
}
