import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { business, call, callWhite, img1 } from "../../../assets/images";
import { FiCamera, FiSearch } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { collection, doc, getDoc, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase";
import { useSelector } from "react-redux";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { MdInsertPhoto } from "react-icons/md";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { httpRequest } from "../../../Apis/commonApis";
import UserItem from "./UserItem";
import { compareDates } from "../../../constants/Constants";
const User = (props: any) => {
  const {
    handleChatSelection,
    chatmessage,
    selectedChat,
  } = props;
  const location = useLocation()
  const [searchTxt, setSearchTxt] = useState("");
  const [generalChatList, setGeneralChatList] = useState<any>([])
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [superAdminChat, setSuperAdminChat] = useState<any>({})
  const [chatUsers, setChatUser] = useState<any>([]);

  const [allUser, setAllUser] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false)

  async function setChatHandler() {
    const bookingId = location?.state?.appointment;
    setLoading(true)
    const response = await httpRequest(`getBooking?_id=${bookingId}`, 'GET', null, 'json')
    if (response?.status) {
      const booking = response?.data[0];
      const client = booking?.Client
      if (booking) {
        const { booking_Id, clientId, clientUid, branchId, businessId, finalPrice, salectDate, salectTime, services, _id } = booking;
        const { firstName, lastName, mobile, email, } = client;
        const bookingData = {
          booking_Id,
          clientId,
          branchId,
          businessId,
          clientUid,
          finalPrice,
          salectDate,
          salectTime,
          services: services?.map((serviceObj: any) => {
            const TeamMemberId = serviceObj?.TeamMemberId?.map((team: any) => ({ firstName: team?.firstName, lastName: team?.lastName, nickName: team?.nickName }))
            return { serviceId: { ...serviceObj?.serviceId, serviceId: serviceObj?.serviceDetails }, _id: serviceObj?._id, TeamMemberId }
          })
        }
        try {
          const chatRef = doc(db, "userChat", branchId, 'booking', booking?._id)
          const res = await getDoc(chatRef);
          if (!res.exists()) {
            await setDoc(chatRef, {
              createdAt: serverTimestamp(),
              updateMessage: serverTimestamp(),
              businessId,
              branchId,
              Client: { firstName, lastName, mobile, email },
              lastMsg: '',
              logo: booking?.Business?.profileImage || '',
              type: 'user',
              businessName: booking?.Business?.businessName,
              bookingData,
              isOnline: true,
              messages: [],
              bookingId: _id,
            });
            const newRes = await getDoc(chatRef);
            const newDocData = newRes.data();
            if (newDocData) {
              handleChatSelection(newDocData);
            }
          } else {
            const docData = res.data();
            if (docData) {
              handleChatSelection(docData)
            }
          }
          setLoading(false)
        } catch (err) { }
        try {
          const res = await getDoc(doc(db, "userChat", client?.cuId, 'booking', booking?._id));
          if (!res.exists()) {
            await setDoc(doc(db, "userChat", client?.cuId, 'booking', booking?._id), {
              createdAt: serverTimestamp(),
              updateMessage: serverTimestamp(),
              businessId,
              branchId,
              Client: { firstName, lastName, mobile, email },
              lastMsg: '',
              logo: booking?.Business?.profileImage || '',
              type: 'user',
              businessName: booking?.Business?.businessName,
              bookingData,
              isOnline: true,
              messages: [],
              bookingId: _id,
            });
          }
        } catch (err) { }
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (location?.state?.appointment) {
      setChatHandler()
    }
  }, [location?.state])

  useEffect(() => {
    if (branchId) {
      const unSub = onSnapshot(
        collection(db, "userChat", branchId, 'booking'),
        (querySnapshot) => {
          let headdata: any = [];
          querySnapshot.forEach((doc) => {
            headdata.push(doc.data())
          });

          let allUser = [...headdata, ...generalChatList]
          // allUser = allUser?.sort((a: any, b: any) => b?.updateMessage - a?.updateMessage)
          setChatUser(allUser);
          setAllUser(allUser)
        }
      );
      return () => {
        unSub()
      };
    }
  }, [branchId, generalChatList]);

  async function setUpdateUser() {
    try {
      const res = await getDoc(doc(db, "superadminuser", businessData?._id));
      if (!res.exists()) {
        await setDoc(doc(db, "superadminuser", businessData?._id), {
          createdAt: serverTimestamp(),
          businessId: businessData?._id,
          lastMsg: '',
          type: '',
          isOnline: false,
          isSeen: false,
          messages: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (businessData?._id) {
      setUpdateUser()
      const unSub = onSnapshot(
        doc(db, "superadminuser", businessData?._id),
        (querySnapshot) => {
          if (querySnapshot?.data()) {
            setSuperAdminChat({ ...querySnapshot?.data() })
          }
        }
      );
      const unGeneralSub = onSnapshot(
        collection(db, "generalBusinessChat", businessData?._id, 'business'),
        (querySnapshot) => {
          let headdata: any = [];
          querySnapshot.forEach((doc) => {
            headdata.push(doc.data())
          });
          setGeneralChatList(headdata?.map((item: any) => ({ ...item, Client: { ...item }, chatType: 'generalUser' })))
        }
      );

      return () => {
        unSub();
        unGeneralSub()
      };
    }
  }, [businessData]);

  useEffect(() => {
    if (searchTxt !== "") {
      let messagesWithKeyword: any = chatUsers?.filter((item: any) => {
        if (Array.isArray(item.allMessage)) {
          return item.allMessage.some((message: any) => {
            if (typeof message === 'string' && message.toLowerCase()?.replaceAll(" ", '')?.includes(searchTxt.toLowerCase()?.replaceAll(" ", ''))) {
              item.lastMsg = message;
              // setFilterMessage(message)
              chatmessage(message)
              return true;
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
      const itemsWithFirstName: any = chatUsers?.filter((item: any) => `${item?.Client?.firstName?.toLowerCase()?.replaceAll(" ", '')}${item?.Client?.lastName?.toLowerCase()?.replaceAll(" ", '') || ''}`?.includes(searchTxt.toLowerCase()?.replaceAll(" ", '')));

      let combinedResults = [...itemsWithFirstName];
      setAllUser(combinedResults)

    } else {
      let list = [...chatUsers];
      list = list?.sort((a: any, b: any) => {
        if (!b?.updatedMessage) {
          return -1
        }
        return b.updateMessage - a.updateMessage;
      });
      setAllUser(list);
    }
  }, [searchTxt]);

  const handleItemClick = (item: any) => {
    if (item?.chatType === 'admin' || item?.chatType === 'generalUser') {
      handleChatSelection(item);
      return
    }
    const idValue = item?._id || '';
    const replacedIdValue = idValue.replace(/-/g, '')
    const modifiedData: any = { ...item, id: item?._id, _id: replacedIdValue };
    handleChatSelection(modifiedData);
  };

  let timeStamp: any = superAdminChat?.updateMessage
  let timeDate: any;

  if (timeStamp) {
    timeDate = new Date(timeStamp?.seconds * 1000 + timeStamp?.nanoseconds / 1000000)
  }

  const options: any = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);

  const formattedDate = timeDate ? formatter.format(timeDate) : ""
  const date = timeDate ? formattedDate?.split(",")?.[0] : ""
  const time = timeDate ? formattedDate?.split(",")?.[1] : "";
  const checkIfBookingToday = !formattedDate ? false : compareDates(date, new Date()) === 0;

  const dynamicDate = !formattedDate ? "" : checkIfBookingToday ? time : date

  // const filterUser = selectedChat ? allUser.sort((a:any, b:any) => {
  //   const selectedBookingId = (selectedChat?.bookingData?.booking_Id || selectedChat?.bookingData?.businessBookingId)
  //   const bookingIdA = (a?.type !== 'admin' || a?.chatType !== 'generalUser') ? (a?.bookingData?.booking_Id || a?.bookingData?.businessBookingId) : ''
  //   const bookingIdB = (b?.type !== 'admin' || b?.chatType !== 'generalUser') ? (b?.bookingData?.booking_Id || b?.bookingData?.businessBookingId) : ''
    
  //   if((a?.chatType === "generalUser" && selectedChat?.chatType === 'generalUser' && a?.clientId === selectedChat?.clientId) || (a?.chatType !== "generalUser" && selectedChat?.chatType !== 'generalUser' && bookingIdA === selectedBookingId)){
  //     return -1
  //   }
  //   if((b?.chatType === "generalUser" && selectedChat?.chatType === 'generalUser' && b?.clientId === selectedChat?.clientId) || (b?.chatType !== "generalUser" && selectedChat?.chatType !== 'generalUser' && bookingIdB === selectedBookingId)){
  //     return 1
  //   }
  // }) : allUser?.sort((a:any, b:any) => b?.updateMessage - a?.updateMessage)
  const filterUser = selectedChat ? allUser : allUser?.sort((a:any, b:any) => b?.updateMessage - a?.updateMessage)

  return (
    <>
      <Col className={`${cx.chatList} p-0 ${"col"}`}>
        <Col md={12}>
          <div className="text-end">
            {/* <HelpButon number={53} type={"help"} className={"btn"} /> */}
          </div>
        </Col>
        <div className={`${cx.chatHeading} justify-content-between gap-2`}>
          <span>Chats</span>
          <HelpButon number={53} type={"help"} className={"btn"} />
        </div>
        <div className={`${cx.chatSearchBox}`}>
          <FiSearch className={`${cx.searchBtn}`} />
          <input type="search" className="form-control" value={searchTxt}
            placeholder="Search"
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }} />
        </div>
        <div className={`${cx.businessListItem}`}>
          <button
            className={`${cx.listMenu} ${superAdminChat?.businessId === selectedChat?.id ? cx.active : ''}`}
            onClick={() => handleItemClick({ ...superAdminChat, chatType: 'admin', _id: superAdminChat?.businessId, id: superAdminChat?.businessId, Client: { firstName: 'Business', lastName: 'Admin' } })}>
            <div className={cx.businessIconBox}>
              <span>BA</span>
            </div>
            <div className={`${cx.chatListBody_mainBox}`}>
              <div className={`${cx.chatListBody}`}>
                <h5 title='Business Admin'>
                  Business Admin
                </h5>
                <span>{dynamicDate}</span>

              </div>
              <p>
                {superAdminChat?.type === 'img' ? (
                  <img style={{
                    height: "13px",
                    width: "30px",
                    objectFit: "cover"
                  }}
                    src={superAdminChat?.lastMsg} alt='upload-img' />
                ) : superAdminChat?.type === 'text' ? superAdminChat?.lastMsg : (
                  <div style={{ color: "#fff", background: '#af0d0dde', width: '40px', textAlign: 'center', borderRadius: '5px', margin: '0', fontSize: '11px' }}>{superAdminChat?.type?.toLocaleUpperCase()}</div>
                )}
              </p>
            </div>
          </button>
        </div>
        <ul>
          {loading && (<li><button className={`${cx.listMenu}`}>
            <div className={`${cx.chatListBody}`}>
              <h5 style={{ color: "#3e8575", fontSize:'14px' }}>
                Fetching...
              </h5>
            </div>
          </button></li>) }
          {!loading && filterUser?.map((item: any, i:number) => {
            return (
                <UserItem item={item} selectedChat={selectedChat} handleItemClick={handleItemClick} key={i}/>
              )
          })}
        </ul>
      </Col>
      {(!selectedChat) && (
        <Col md={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
          <div className={`${cx.noAppoint_yet}`}>
            <div className={`${cx.icon}`}>
              <BiSolidMessageSquareDetail />
            </div>
            <h4>Select a conversation from the list to start chatting</h4>
          </div>
        </Col>
      )}
    </>
  );
};

export default User;
