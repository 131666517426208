import { useState } from "react";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import ViewHistoryModal from "../../../components/Business/Modals/ViewHistoryModal";
import { useLocation } from "react-router-dom";

export default function BookingItem({ cx, client }: any) {
    const [showHistory, setShowHistory] = useState(false);
    const handleHistoryClose = () => setShowHistory(false);
    const handleHistoryShow = () => setShowHistory(true);
    const location = useLocation();
    const [data, setData] = useState<any>({});

    const totalDuration = client?.services?.reduce((acc: any, val: any) => {
        return Number.parseInt(val?.serviceId?.duration) + acc
    }, 0)

    const timeArr = client?.salectTime?.split(":");
    const startMinutes = Number(timeArr?.[0]) * 60 + Number(timeArr?.[1])
    const endMinutes = startMinutes + totalDuration

    let formattedStartTime = convertMinutesToTime(startMinutes);
    let formattedEndTime = convertMinutesToTime(endMinutes);

    let assignTeam: any = [];
    client?.services?.forEach((service: any) => {
        if (service?.TeamMemberId?.[0]?._id) {
            assignTeam?.push(service?.TeamMemberId?.[0])
        }
    })
    const finalData = { ...data, services: data?.services?.map((item: any) => ({ ...item, serviceDetails: item?.serviceId?.serviceId })) }
    return (
        <>
            <ViewHistoryModal
                showHistory={showHistory}
                handleHistoryClose={handleHistoryClose}
                data={{ ...finalData, Client: location?.state }}
                type="client"
            />
            <div
                className={`${cx.bookingTable}`}
                style={{ borderLeft: "15px solid #63A1E9" }}
                key={client?._id}
            >
                <div className={`${cx.tableCell}`}>
                    <b>{formattedStartTime} - {formattedEndTime}</b>
                </div>
                <div className={`${cx.tableCell}`}>
                    <b>{client?.guestname ? `(${client?.guestname})` : ""}</b>
                </div>

                <div className={`${cx.tableCell}`}>
                    <b>{client?.bookedBy === "offline" ? `Direct` : "Wellnezza"}</b>
                </div>

                <div className={`${cx.tableCell}`}>
                    <b>{client?.bookingStatus ? `(${client?.bookingStatus === "Upcoming" ? "Open" : client?.bookingStatus})` : ""}</b>
                </div>
                <div className={`${cx.tableCell} ${cx.serviceList}`}>
                    {client?.services?.map((ser: any) => ser?.serviceId?.serviceId?.serviceName)?.join(", ")}
                </div>
                <div className={`${cx.tableCell} ${cx.branchCell}`}>
                    {client?.branchId?.branchName || ""}
                </div>
                <div className={`${cx.tableCell} ${cx.teamList}`}>
                    {assignTeam?.length === 0 ? "Unassigned" : assignTeam?.map((team: any) => ` ${team?.firstName ? team?.firstName : ""} ${team?.lastName ? team?.lastName : ""}`)?.join(",")}
                </div>
                <div className={`${cx.tableCell}`}>{client?.finalPrice} SGD</div>
                {client?.paymentStatus && <div className={`${cx.tableCell}`}>{client?.paymentStatus === "Pending" ? "Payment Pending" : "Payment Completed"}</div>}
                <div className={`${cx.tableCell}`} style={{ color: "#509F8E", cursor: "pointer", minWidth: '145px', maxWidth: '145px' }} onClick={() => {
                    setData(client)
                    handleHistoryShow()
                }}>View History</div>


                {/* <div className={`${cx.tableCell}`}>
                          <Dropdown className={`${cx.dropDownList}`}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <MdOutlineMoreVert />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <ul>
                                <li>
                                  <NavLink href="#">Edit</NavLink>
                                </li>
                                <li>
                                  <NavLink href="#">Delete</NavLink>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
            </div>
        </>
    )
}