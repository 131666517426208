import React, { useEffect, useState } from 'react'
import { deleteIcon, editButton, iconLogoGrey } from '../../../assets/images'
import { Col } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import BundleServiceTable from './BundleServiceTable';
import { httpRequest } from '../../../Apis/commonApis';
import { DeletePopup } from '../../../components/Business/Modals';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';
import ConfirmationModal from '../../../components/Business/Modals/ConfirmationModal';

export default function BundleCard({ bundle, getBundle, handleBundleCanvasShow, type = 'all' }: any) {
    const [apiMessage, setApiMessage] = useState<any>("");
    const [isPublished, setIsPublished] = useState<boolean>(false)
    const navigate = useNavigate()

    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
    }

    const [showConfirmation, setShowConfirmation] = useState<any>(false)
    const handleConfirmationClose = () => {
        setShowConfirmation(false)
    }

    console.log(bundle?.priceId, 'bundleDataaaaaa')
    const deleteApi = async () => {
        const res = await httpRequest(
            `deleteBundle/${bundle?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res.status) {
            getBundle();
            handleClose();
            setApiMessage('')
        } else {
            setApiMessage(res?.message)
        }
    };

    const handlePublish = async (e: any) => {
        const checked = !isPublished;
        if (checked && !bundle.isActive) {
            handleAlertModalShow(`Error, you can't publish this bundle because it's status is disabled`)
            return
        }
        if (checked && bundle?.priceId?.length === 0) {
            handleAlertModalShow(`Error, you can't publish this bundle please assign service first`)
            return
        }
        const formdata = new FormData();
        formdata.append('isPublish', JSON.stringify(checked))
        const res = await httpRequest(`updateBundle/${bundle?._id}`, 'PATCH', formdata, 'formdata');
        if (res?.status) {
            getBundle && getBundle()
            handleAlertModalShow(`Success, bundle is ${res?.data?.isPublish ? 'published' : 'unpublished'}`)
        } else {
            handleAlertModalShow(`Error, bundle publishing status is not updated`)
        }
        handleConfirmationClose()
    }

    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const canEditBundle = subModuleRoles?.['Services']?.Bundles?.Edit;
    const canDeleteBundle = subModuleRoles?.['Services']?.Bundles?.Delete;

    useEffect(() => {
        setIsPublished(bundle?.isPublish)
    }, [bundle?.isPublish])
    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <DeletePopup
                show={show}
                apiMessage={apiMessage}
                handleClose={handleClose}
                deleteApi={deleteApi}
            />
            <ConfirmationModal
                showConfirmation={showConfirmation}
                message={`${isPublished ? 'unpublish' : 'publish'} this bundle`}
                handleConfirmationClose={handleConfirmationClose}
                onConfirm={handlePublish} />

            <Col md={12}>
                <div className={`${st.edit_publishBranchFlex}`}>
                    <div className={`${st.edit_publishBranchMain}`}>
                        <div className={`${st.topBox_withBtn} mb-2`}>
                            <h5>{bundle?.bundleName}</h5>
                            <ul className={`${st.btnList} align-items-center`}>
                                {canEditBundle && <li onClick={() => {
                                    const route = type === 'publish' ? '/business/publish/edit-bundle/info' : '/business/services/edit-bundle/info'
                                    navigate(route, { state: { bundleId: bundle?._id } })
                                    handleBundleCanvasShow()
                                }}>
                                    <button> <img src={editButton} alt="edit" /></button>
                                </li>}
                                {canDeleteBundle && type !== 'publish' && <li onClick={handleShow}>
                                    <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                                </li>}
                                <li className='ms-1'>
                                    <span className={`${st.iconLogo_checkBox}`}>
                                        <span className={`${st.borderBox} `}>
                                            <label className={`d-inline-flex ${st.checkbox}`}>
                                                <input type="checkbox" checked={isPublished} onChange={() => setShowConfirmation(true)} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-1`}> <img src={iconLogoGrey} alt="icon" /></span>
                                            </label>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {bundle?.priceId?.length > 0 ? <div className={`${st.tableCollapse}`}>
                            <table className="table" >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Team Title</th>
                                        <th scope="col">Service Type</th>
                                        <th scope="col">Note</th>
                                        <th scope="col">Price (SGD)</th>
                                        <th scope="col">Duration (min)</th>
                                    </tr>
                                </thead>
                                {bundle && <BundleServiceTable bundle={bundle} key='priceTable' />}
                            </table>
                        </div> : (
                            <div className={`${st.tableCollapse}`}>
                                <p className={`${st.noBundleData}`}>No services added in this bundle yet</p>
                            </div>
                        )}

                    </div>
                    {/* <div>
                        <span className={`${st.iconLogo_checkBox}`}>
                            <span className={`${st.borderBox} `}>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`}> <img src={iconLogoGrey} alt="icon" /></span>
                                </label>
                            </span>
                        </span>
                    </div> */}
                </div>
            </Col>
        </>
    )
}
