import React from 'react'
import { Col, Row } from 'react-bootstrap';
import cx from './index.module.scss';
import { formatFormatTime, formatSlashDate } from '../../../constants/Constants';
import { convertMinutesToTime } from '../../../Utils/Funcs';

export default function NotificationItem({ item }: any) {
    const booking = item?.booking
    const bookingDate = formatSlashDate(booking?.salectDate);
    const startTime = formatFormatTime(booking?.salectTime);
    const totalDuration = booking?.totalDuration
    const [startHour, startMinutes] = booking?.salectTime?.split(":")
    const endTotalMinutes = booking?.salectTime ? (+startHour * 60 + +startMinutes) + totalDuration  : 0;
    const endTime = formatFormatTime(convertMinutesToTime(endTotalMinutes));
    
    const services = booking?.services;
    const stylist = booking?.stylist
    return (
        <Col lg={12} md={12}>
           <div className={`${cx.customer} ${booking?.type === 'Cancelled' ? cx.cancel : ''}`}>
           <div className={`${cx.border} ${booking?.type === 'Cancelled' ? cx.cancel : ''}`} role="img"></div>
                <div className={cx.main}>
                    <div className={cx.top}>
                        <Row>
                            <Col lg={3} md={4} sm={4} className="mb-3">
                                <p className={cx.date}>{bookingDate}</p>
                                <p className={cx.time}>{startTime}-{endTime}</p>
                            </Col>
                            <Col lg={2} md={4} sm={4} className="mb-3">
                                <p className={cx.service}>{services?.join(', ')}</p>
                            </Col>
                            <Col lg={2} md={4} sm={4} className="mb-3">
                                <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                                <p className={cx.name}>{stylist?.length > 0 ? stylist?.join(', ') : 'Unassigned'}</p>
                            </Col>
                            <Col lg={3} md={4} sm={4} className="mb-3">
                                <p className={cx.status}>Client</p>
                                <p className={cx.name}>{booking?.client?.name || ''}</p>
                                {booking?.client?.mobile && <p className={cx.status}>{booking?.client?.mobile}</p>}
                            </Col>
                            <Col
                                lg={2}
                                md={3}
                                sm={3}
                                className="mb-3 d-flex align-items-center justify-content-between"
                            >
                                <p className={cx.name}>SGD {booking?.finalPrice}</p>
                            </Col>
                        </Row>
                    </div>
                    <div className={`${cx.bottom} ${booking?.type === 'Cancelled' ? cx.cancel : ''}`}>
                        <p className={`${booking?.type === 'Cancelled' ? cx.cancel : ''}`}>{item?.message}</p>
                        {/* <button>View Message</button> */}
                    </div>
                </div>
            </div>
        </Col>
    )
}
