import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const ContactDetails = (props: any) => {
  let { show, handleContactDetailsClose,clientDetails } = props;
 
  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleContactDetailsClose}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.add_card_detailMain} ${m.contact_detailMain}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Info</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:'auto'}}>

          <div className={`${m.checkPopup}`}>
            {/* <p className={`${m.info_blow}`}> Contact information below:</p> */}

            <form>
              <Row>
                <Col md={12}>
                  <div className={`${m.inputForm} mb-3`}>
                    <label>Client Name</label>
                    <div className={`${m.inputBox}`}>
                      <div className={`form-control`}>{clientDetails?.firstName} {clientDetails?.lastName}</div>
                    </div>
                  </div>
                </Col>
                {clientDetails?.email && <Col md={12}>
                  <div className={`${m.inputForm} mb-3`}>
                    <label>Client Email</label>
                    <div className={`${m.inputBox}`}>
                      <div className={`form-control`}>{clientDetails?.email}</div>
                    </div>
                  </div>
                </Col>}
                {clientDetails?.mobile && <Col md={12}>
                  <div className={`${m.inputForm} mb-3`}>
                    <label>Client Number</label>
                    <div className={`${m.inputBox}`}>
                      <div className={`form-control`}>{clientDetails?.mobile}</div>
                    </div>
                  </div>
                </Col>}
              </Row>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactDetails;
