import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Dropdown, Nav, NavLink, Row, Tab, Table } from "react-bootstrap";
import {
  asDefalut,
  basicInfo,
  basicInfoA,
  emailIcon,
  location,
  membership,
  phoneIcon,
  profile,
  profileImg,
  teamPerson1,
} from "../../../assets/images";
import { MdModeEditOutline, MdOutlineMoreVert } from "react-icons/md";
import { FaHome, FaStar } from "react-icons/fa";
import EditClient from "../../../components/Business/Modals/EditClient";
import { useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { DeletePopup } from "../../../components/Business/Modals";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import { formatDateFormat, formatTime } from "../../../constants/Constants";
import ViewHistoryModal from "../../../components/Business/Modals/ViewHistoryModal";
import { BiGridAlt } from "react-icons/bi";
import { FiList } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";
import BookingItem from "./BookingItem";

const ViewClient = (props: any) => {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState<any>({});
  const [teamList, setTeamList] = useState<any>([]);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const [activeButton, setActiveButton] = useState("Open");
  const [defaultIcon, setdefaultIcon] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const getClientProfile = async () => {
    const res = await httpRequest(`getClientProfile?businessId=${businessData?._id}&id=${location?.state?._id}`, "get", null, "json");
    setClientData(res?.data)
  }
  const getAllTeamMember = async () => {
    const res = await httpRequest(`getAllTeamMembers?businessId=${businessData?._id}&type=list`, "get", null, "json");
    setTeamList(res?.data)
  }
  useEffect(() => {
    if (location?.state?._id) {
      getClientProfile()
      getAllTeamMember()
    }
  }, [location?.state])

  const [showEditClient, setShowEditClient] = useState(false);
  const handleEditClientClose = () => setShowEditClient(false);
  const handleEditClientShow = () => setShowEditClient(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteClient/${location?.state?._id}`,
      "DELETE",
      null,
      "json"
    );
    if (res?.status === true) {
      handleClose();
      navigate("/business/clients", { replace: true })
    }
  };


  let groupByDate = (bookings: any) => {
    if (!bookings) {
      return
    }
    const grouped: any[] = [];
    bookings.forEach((event: any) => {
      const date: any = event?.salectDate;
      const existingGroup = grouped.find(group => group?.date === date);
      if (existingGroup) {
        existingGroup?.events?.push(event);
      } else {
        grouped?.push({ date, events: [event] });
      }
    });
    return grouped;
  }

  const bookingInGroup: any = groupByDate(clientData?.bookingData);
  useEffect(() => {
    let defaultIcon;
    if (location?.state?.firstName && location?.state?.lastName) {
      defaultIcon = location?.state?.firstName.slice(0, 1) + location?.state?.lastName.slice(0, 1)
    } else {
      defaultIcon = location?.state?.firstName.slice(0, 2)
    }
    defaultIcon = defaultIcon.toUpperCase();
    setdefaultIcon(defaultIcon)
  }, [location?.state])

  const formatDate = (date: any) => {
    if (date) {
      const d = new Date(date);
      const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(d);
      return formattedDate
    }
  }

  const calculateMembershipEndDate = (membership: any) => {
    const startDate = membership?.membershipUseStartDate;
    if (startDate) {
      let membershipEndDate = new Date(startDate);
      const endDuration = membership ? membership?.ValidityUnit : 0;
      if (membership && membership?.ValidityFrequency === "Month") {
        membershipEndDate?.setMonth(membershipEndDate.getMonth() + endDuration)
      }
      if (membership && membership?.ValidityFrequency === "Day") {
        membershipEndDate?.setDate(membershipEndDate.getDate() + endDuration)
      }
      if (membership && membership?.ValidityFrequency === "Year") {
        membershipEndDate?.setFullYear(membershipEndDate.getFullYear() + endDuration)
      }
      if (membership && membership?.ValidityFrequency === "Week") {
        membershipEndDate?.setDate(membershipEndDate.getDate() + 7 + endDuration)
      }

      return membershipEndDate
    } else {
      return new Date()
    }
  }

  const membershipData = clientData?.membershipHistory?.[0] ? clientData?.membershipHistory?.flat()?.map((item: any) => item && JSON.parse(item))?.sort((a: any, b: any) => +a?.membershipNumber - +b?.membershipNumber) : null

  const businessReview = clientData?.clientReview?.find((item: any) => item?.businessId === businessData?._id)

  return (
    <>
      <DeletePopup
        show={show}
        deleteApi={deleteApi}
        handleClose={handleClose}
        message="client"
      />
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        {/* <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {" "}
              <BackArrrow /> 
              {`${location?.state?.firstName || ""} ${location?.state?.lastName || ""}`}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {<div style={{ color: "#3e8575", padding: "5px 50px" }}>Display Mode</div>}
          </div>
        </div> */}

        <div className={`${st.newRedesign_main}`}>
          {/* <Row>
            <Col md={12}>
              <div className={`${st.breadCrumb_All}`}>
                <ul>
                  <li className={`${st.homeBtn}`}>
                    <button><FaHome /></button>
                  </li>
                  <li className={`${st.mainPages}`}>
                    <IoIosArrowForward className={`${st.arrowForard}`} />
                    <button>
                      Customer</button>
                  </li>
                  <li className={`${st.currentPage}`}>
                    <IoIosArrowForward className={`${st.arrowForard}`} />
                    <span>View Customer Details</span></li>
                </ul>
              </div>
            </Col>
          </Row> */}
          <BreadCrumbs main={{ menu: 'Customer', link: '/business/clients' }} current={{ menu: 'View Customer Details', link: 'disabled' }} />

          <Row>
            <Col md={12}>
              <div className={`${st.head_paraBox} mb-0`}>

                <div className="d-flex align-items-center gap-4 mb-4">
                  <div>
                    {location.state.image ? (
                      <div className={`${cx.profileCardImg}`}>
                        <img src={location.state.image} alt="profile" />
                      </div>) : (
                      <div className={cx.profileBox}>
                        <span>{defaultIcon}</span>
                      </div>)}
                  </div>
                  <div>
                    <h2>{location?.state?.firstName + " " + location?.state?.lastName || ""}</h2>
                    <p>Add the team members . You can differentiate  team member providing customer
                      service and  support service by check box selection  in team title  </p>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col md={12}>
              <div className={`${st.gridList_view} justify-content-end mb-4`}>
                <button className="btn grid active">
                  <BiGridAlt />
                </button>
                <button className="btn listView">
                  <FiList />
                </button>
              </div>
            </Col> */}
          </Row>
        </div>

        <div className={`${st.tabbingBox} mt-0`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <div className={`${st.tabbing_pillBox}`}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="tabNew1">
                    Basic Info
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="tabNew2">
                    Bookings
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="tabNew3">
                    Review
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className={`${st.display_Mode}`}>Display Mode</div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col md={4} className="mb-4">
                    <div className={`${cx.profileCard}`}>
                      {location.state.image ? (
                        <div className={`${cx.profileCardImg}`}>
                          <img src={location.state.image} alt="profile" />
                        </div>) : (
                        <div className={cx.profileBox}>
                          <span>{defaultIcon}</span>
                        </div>)}
                      <h5>{location?.state?.firstName + " " + location?.state?.lastName || ""}</h5>
                      {/* <p><span>Source :</span> {location?.state?.source === "Business" ? "Direct" : location?.state?.source}</p>
                      <p>{location?.state?.country || ""}</p>
                      <p>{location?.state?.updatedAt?.split('T')[0]}</p> */}
                    </div>

                    <div className={`${cx.profileCard} mt-3`}>
                      <p className="mt-0"><span>Source :</span> {location?.state?.source === "Business" ? "Direct" : location?.state?.source}</p>
                      <p>{location?.state?.country || ""}</p>
                      <p>{location?.state?.updatedAt?.split('T')[0]}</p>
                    </div>

                  </Col>
                  <Col md={8} className="mb-4">
                    <div className={`${cx.informationCard}`}>
                      <div>
                        <h5>
                          {location?.state?.firstName}  {location?.state?.lastName || ""}
                        </h5>
                        <ul>
                          <li>
                            <span className={`${cx.informationHeading}`}>
                              DOB
                            </span>{" "}
                            {/* 25.03.1997 */}
                            {formatDateFormat(location?.state?.dateOfBirth)}
                          </li>
                          <li>
                            {/* <span>Email</span> bewanewa@gmail.com */}
                            <span>Email</span> {location?.state?.email}
                          </li>
                          <li>
                            <span>Phone</span> {location?.state?.mobile}
                          </li>
                          <li>
                            <span>Location</span> {location?.state?.adderss1} {location?.state?.adderss2}
                            {/* 70800 */}
                          </li>
                        </ul>
                      </div>

                      <p className={`${cx.deleteAccount}`} onClick={handleShow}>
                        Delete Account
                      </p>
                    </div>
                  </Col>

                  <Col md={12}>
                    <Row>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.noShow ? clientData?.noShow : "0"}</h5>
                          <p>No Show</p>
                        </div>
                      </Col>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.completed ? clientData?.completed : "0"}</h5>
                          <p>Completed</p>
                        </div>
                      </Col>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.visitsBooked ? clientData?.visitsBooked : "0"}</h5>
                          <p>Visits Booked</p>
                        </div>
                      </Col>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.canceled ? clientData?.canceled : "0"}</h5>
                          <p>Canceled</p>
                        </div>
                      </Col>
                    </Row>
                    {clientData?.membershipHistory?.length >= 1 ? (
                      membershipData?.map((item: any, index: number) => {
                        if (item?.businessId === businessData?._id) {
                          return (
                            <div className={`${cx.informationMembershipCard} mt-4`} key={item?._id}>
                              <h5>
                                Membership
                              </h5>
                              <Row className="mt-3" >
                                <Col md={4} className={`${cx.membershipBox}`}>
                                  <div className={cx.box}>
                                    <h5 className={cx.businessName}>{item?.Business?.businessName || ""}</h5>
                                    <div className={cx.memberInfo}>
                                      <h4>{item?.Membership}</h4>
                                      <h6>{item?.membershipNumber ? item?.membershipNumber : ` 4242 4242`}</h6>
                                    </div>
                                  </div>

                                </Col>
                                <Col md={8} className={`${cx.membershipBody}`}>
                                  {/* <h4>{item?.Business?.businessName || ""}</h4> */}
                                  <h3 className={cx.memberName}>{item?.Membership}</h3>
                                  <div className={cx.number}>
                                    <h3 className={cx.numberHeading}>Membership number</h3>
                                    <h6 className={cx.value}>: {item?.membershipNumber ? item?.membershipNumber : ` 4242 4242`}</h6>
                                    {/* <h6 className={cx.value}>: {` 1101`}</h6> */}
                                  </div>
                                  {item?.tier == 0 && <p>Free for lifetime</p>}
                                  {item?.tier !== 0 && <p>Start date: {formatDate(item?.membershipUseStartDate)} - Ending on: {formatDate(calculateMembershipEndDate(item))}</p>}

                                </Col>
                              </Row>
                            </div>
                          )
                        }
                      })) : ""}
                    {/* {clientData?.membershipHistory?.length >= 1 ? (
                      clientData?.membershipHistory?.map((val: any) => {
                        let item: any = JSON.parse(val);

                        return (
                          <div className={`${cx.informationCard}`}>

                            <h5>
                              Membership
                              <NavLink className={`${cx.editIcon}`} href="#">
                                <MdModeEditOutline />
                              </NavLink>
                            </h5>
                            <Row className="mt-3">
                              <Col md={4} className={`${cx.membershipImg}`}>
                                <img src={membership} alt="membership" />
                              </Col>
                              <Col md={8} className={`${cx.membershipBody}`}>
                                <h4>{item?.Membership}</h4>
                                <h4>Membership number: {item?.membershipNumber}</h4>
                                <p>Start date: 09/12/2019 - Ending on: 08/12/2029</p>
                                <button className={`btn ${cx.cancelMembership}`}>
                                  Cancel membership
                                </button>
                              </Col>
                            </Row>
                          </div>)
                      })
                    ) : ("")} */}
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew2">
                <div
                  className={`${st.buttonsAction} ${st.buttonflexDiv} justify-content-end`}
                >
                  <div className={`${st.threeButton} ${cx.threeBtn}`}>
                    <ul className={`${cx.bookingFilter}`}>
                      <li>
                        <button className={`${st.btn} ${activeButton === "Open" ? st.active : ""}`} onClick={() => setActiveButton("Open")}>
                          Open
                        </button>
                      </li>
                      <li>
                        <button className={`${st.btn} ${activeButton === "Completed" ? st.active : ""}`} onClick={() => setActiveButton("Completed")}>Completed</button>
                      </li>
                      <li>
                        <button className={`${st.btn} ${activeButton === "Cancelled" ? st.active : ""}`} onClick={() => setActiveButton("Cancelled")}>Cancelled</button>
                      </li>
                      <li>
                        <button className={`${st.btn} ${activeButton === "No Show" ? st.active : ""}`} onClick={() => setActiveButton("No Show")}>No Show</button>
                      </li>
                    </ul>
                  </div>
                </div>


                {bookingInGroup && bookingInGroup?.map((booking: any, i: any) => {
                  const bookingDate = booking?.date ? new Date(booking?.date)?.toDateString() : "";
                  const isToday = bookingDate === new Date().toDateString();

                  const filterBookings = activeButton === "Cancelled" ? booking?.events?.filter((data: any) => {
                    if (data?.bookingStatus === "Cancelled" || data?.bookingStatus === "Client Cancelled") {
                      return data
                    }
                  }) : booking?.events?.filter((data: any) => activeButton === "Open" ? data?.bookingStatus === "Upcoming" : data?.bookingStatus === activeButton);
                  if (filterBookings?.length === 0) {
                    if (i === 0) {
                      return <h5 className={cx.bookingStatus}>No {activeButton} Booking Found</h5>
                    } else {
                      return
                    }
                  }

                  return (
                    <React.Fragment key={booking?.date}>
                      <h5 className={`${cx.titleBox}`}>
                        {bookingDate}
                        {isToday && <span className={`${cx.tagBox}`}>Today</span>}
                      </h5>
                      {filterBookings?.map((client: any) => {
                        return (
                          <BookingItem cx={cx} client={client} key={client?._id}/>
                        )
                      })}
                    </React.Fragment>
                  )
                })}




                {/* <div
                  className={`${cx.bookingTable}`}
                  style={{ borderLeft: "15px solid #B842FF" }}
                >
                  <div className={`${cx.tableCell}`}>
                    <b>10:20 AM-11:20 AM</b>
                  </div>
                  <div className={`${cx.tableCell}`}>Freehand/Balayage</div>
                  <div className={`${cx.tableCell}`}>
                    <div className={`${cx.stylistCard}`}>
                      <img src={profile} alt="Profile" />
                      <div className={`${cx.stylistCardBody}`}>
                        <h5>Khrystyna Chepis</h5>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={phoneIcon} alt="phone" />
                        </button>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={emailIcon} alt="email" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${cx.tableCell}`}>75USD</div>
                  <div className={`${cx.tableCell}`}>
                    <Dropdown className={`${cx.dropDownList}`}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MdOutlineMoreVert />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <NavLink href="#">Edit</NavLink>
                          </li>
                          <li>
                            <NavLink href="#">Delete</NavLink>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div
                  className={`${cx.bookingTable}`}
                  style={{ borderLeft: "15px solid #3E8575" }}
                >
                  <div className={`${cx.tableCell}`}>
                    <b>10:20 AM-11:20 AM</b>
                  </div>
                  <div className={`${cx.tableCell}`}>Freehand/Balayage</div>
                  <div className={`${cx.tableCell}`}>
                    <div className={`${cx.stylistCard}`}>
                      <img src={profile} alt="Profile" />
                      <div className={`${cx.stylistCardBody}`}>
                        <h5>Khrystyna Chepis</h5>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={phoneIcon} alt="phone" />
                        </button>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={emailIcon} alt="email" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${cx.tableCell}`}>75USD</div>
                  <div className={`${cx.tableCell}`}>
                    <Dropdown className={`${cx.dropDownList}`}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MdOutlineMoreVert />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <NavLink href="#">Edit</NavLink>
                          </li>
                          <li>
                            <NavLink href="#">Delete</NavLink>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <h5 className={`${cx.titleBox}`}>Friday 21st Mar, 2023</h5>
                <div
                  className={`${cx.bookingTable}`}
                  style={{ borderLeft: "15px solid #63A1E9" }}
                >
                  <div className={`${cx.tableCell}`}>
                    <b>10:20 AM-11:20 AM</b>
                  </div>
                  <div className={`${cx.tableCell}`}>Freehand/Balayage</div>
                  <div className={`${cx.tableCell}`}>
                    <div className={`${cx.stylistCard}`}>
                      <img src={profile} alt="Profile" />
                      <div className={`${cx.stylistCardBody}`}>
                        <h5>Khrystyna Chepis</h5>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={phoneIcon} alt="phone" />
                        </button>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={emailIcon} alt="email" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${cx.tableCell}`}>75USD</div>
                  <div className={`${cx.tableCell}`}>
                    <Dropdown className={`${cx.dropDownList}`}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MdOutlineMoreVert />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <NavLink href="#">Edit</NavLink>
                          </li>
                          <li>
                            <NavLink href="#">Delete</NavLink>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div> */}
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew3">
                {businessReview?.reviews?.map((item: any, i: number) => {
                  if (item?.clientId?._id !== location?.state?._id) {
                    return
                  }
                  const toalStylist = item?.bookingId?.services?.map((item: any) => `${item?.TeamMemberId?.firstName || ""} ${item?.TeamMemberId?.lastName || ""}`)?.join(",")
                  const totalServices = item?.bookingId?.services?.map((item: any) => `${item?.serviceId?.serviceId?.serviceName || ""}`)?.join(",")

                  return (
                    <div className={`${cx.reviewCard}`} key={i} >
                      <h6 className="mb-3">{`${formatDate(item?.reviewDate)} ${formatTime(item?.reviewTime)}`}</h6>
                      <div className={`${cx.reviewCardBody}`}>
                        {/* <img
                          src={teamPerson1}
                          alt="team"
                          className={`${cx.teamProfile}`}
                        /> */}
                        <div className={cx.fakeLogo}>
                          <span>TS</span>
                        </div>
                        <div className={`${cx.reviewCardBodyRight}`}>
                          <ul className={`${cx.contentUl}`}>
                            <li>
                              <span>Stylist:</span> {toalStylist || "Any professional"}
                            </li>
                            <li>
                              <span>Total Spent:</span> ${item?.bookingId?.finalPrice}
                            </li>
                            <li>
                              <span>Services:</span>{" "}
                              <b style={{ color: "#1AB192" }}>
                                {totalServices || ""}
                              </b>
                            </li>
                          </ul>
                          <ul className={`${cx.startUl}`}>
                            {[1, 2, 3, 4, 5]?.map((totalReview: any) => {
                              if (totalReview > item?.rating) {
                                return
                              }
                              return (
                                <li>
                                  <FaStar />
                                </li>
                              )
                            })}
                            {/* <li>
                              <FaStar />
                            </li>
                            <li>
                              <FaStar />
                            </li> */}
                          </ul>
                          <p>
                            {item?.comment}
                          </p>

                        </div>
                      </div>
                    </div>
                  )
                })}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section >

      <EditClient
        showEditClient={showEditClient}
        handleEditClientClose={handleEditClientClose}
        page={"Package"}
      />
    </>
  );
};

export default ViewClient;
