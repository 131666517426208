import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import cx from "./index.module.scss";
import {
  basicInfo,
  basicInfoA,
  about,
  aboutA,
  teamV2,
  locationV2,
  teamV2A,
  locationV2A,
  percent,
  percentA,
  editButton,
  upArrow,
} from "../../../assets/images";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import TabRoutes from "./TabRoutes";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useDispatch, useSelector } from "react-redux";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import MultiSelect2 from "../../../components/Business/MultiSelect/MultiSelect2";
import { RxCross2 } from "react-icons/rx";
import { AiFillCamera } from "react-icons/ai";
import { httpRequest } from "../../../Apis/commonApis";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const initialState = {
  businessName: false, companyName: false
}
const AddTeams = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { handleSubmit, setValue, register, formState: { errors } } = useForm()
  let { allBusCategories } = useSelector((state: any) => state.shareDataReducer);
  let { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const canEdit = subModuleRoles?.["Business Set Up"]?.["Business Home"]?.Edit
  
  const dynamicName = getDynamicURIName(location.pathname);
  const [activeTab, setActiveTab] = useState(dynamicName);
  const [isEdit, setIsEdit] = useState(initialState)
  const [serviceProvides, setServiceProvides] = useState<any>('')
  const [ACRANumber, setACRANumber] = useState<string>('')
  const [registeredCompanyName, setRegisteredCompanyName] = useState<string>('')
  const [businessLoading, setBusinessLoading] = useState<string>('')
  const [businessError, setBusinessError] = useState<string>('')

  const [error, setError] = useState('');
  const [savedCats, setSavedCats] = useState<any>([])
  const [logo, setLogo] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedCatIds, setSelectedCatIds] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const [businessName, setBusinessName] = useState<string>('')
  const [business, setBusiness] = useState<any>(null);
  const dispatch = useDispatch()

  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

  useEffect(() => {
    if (dynamicName) {
      setActiveTab(dynamicName);
    }
    if (dynamicName === 'basic') {
      handleTabSelect('info');
    }

  }, [location, dynamicName]);

  const getBusiness = async (udata: any) => {
    let bid = '';
    if (udata?.userType === "teamMember") {
      bid = `businessId=${udata?.businessId}`;
    } else {
      bid = `id=${udata?._id}`;
    }
    let res = await httpRequest(`getBusiness?${bid}`, "get", null, "json");
    if (res.status) {
      let bData = res?.data[0];
      setBusiness(bData);
    }
  };

  useEffect(() => {
    let udata = localStorage.getItem("user_data");
    let userdata = udata ? JSON.parse(udata) : '';
    setUserData(userdata);
    getBusiness(userdata);
  }, []);

  const handleTabSelect = (tab: any) => {
    setActiveTab(tab);
    navigate(`${tab}`, { replace: true });
  };


  useEffect(() => {
    if (business) {
      setBusinessName(business?.businessName || '')
      setServiceProvides(business?.serviceProvides || '')
      setRegisteredCompanyName(business?.registeredCompanyName || '')
      setACRANumber(business?.ACRANumber || '')
      setValue('city', business?.city || '')
      setValue('state', business?.state || '')
      setValue('country', business?.country || '')
      setValue('postalCode', business?.postalCode || '')
      setValue('streetName', business?.streetName || '')
      setValue('floor', business?.floor || '')
      setValue('landmarks', business?.landmarks || '')
      setPreviewImage(business?.profileImage || '')
    }
  }, [business])

  useEffect(() => {
    const savedCats = allBusCategories?.filter(
      (item: any) =>
        business && business?.businessCatagoryId?.includes(item._id)
    );
    const filterSelectedCat = allBusCategories?.filter((cat: any) => business?.businessCatagoryId?.includes(cat?._id))
    setSelectedCatIds(filterSelectedCat?.map((cat: any) => ({ label: cat?.BusinessCategoryName, value: cat?._id })))
    setSavedCats(savedCats)

  }, [business, allBusCategories])

  // async function handleBusinessUpdate(postData: any) {
  //   if (postData == "logo") {
  //     formdata.append('profileImage', "");
  //   }
  //   if (logo) {
  //   }

  //   let res = await httpRequest(
  //     `updateBusiness/${business?._id}`,
  //     "PATCH",
  //     formdata,
  //     "formdata"
  //   );
  //   handleAlertModalShow(`${res.status ? 'Success' : 'Error'}, ${res.message}`);
  //   setError('');
  //   setLoading(false);
  //   if (res.status) {
  //     let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
  //     getBusiness(userData)
  //     if (userdata !== '' && userdata?._id) {
  //       let bid = '';
  //       if (userdata?.userType === "teamMember") {
  //         bid = `businessId=${userdata?.businessId}`;
  //       } else {
  //         bid = `id=${userdata?._id}`;
  //       }
  //       dispatch(getBusinessData({ id: bid }));
  //     }
  //     let bData = res?.data;
  //     setBusiness(bData);
  //     setSelectedCatIds(bData?.businessCatagoryId);
  //     setPreviewImage(bData.profileImage);
  //     setTimeout(() => {
  //       handleAlertModalClose();
  //     }, 2000);
  //   }
  // }

  function onFileChange(event: any) {
    let profileImg = event.target.files[0];
    if (profileImg) {
      handleBusiness('imageUpload', profileImg)
      setLogo(profileImg);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setPreviewImage(e.target.result as string);
        }
      };

      reader.readAsDataURL(profileImg);
    }
    setError('');
  }

  const removeLogo = () => {
    if (logo) {
      setLogo("");
      setPreviewImage("")
    } else { handleBusiness("logo") }
  }

  const handleBusiness = async (type: string, profileImage = '') => {
    const formdata = new FormData()
    if (type === 'businessName') {
      if (businessName === '') {
        setBusinessError('businessName')
        return
      }
      formdata.append('businessName', businessName);
    }
    if (type === "logo") {
      formdata.append('profileImage', "");
    }
    if (type === 'imageUpload') {
      formdata.append('profileImage', profileImage);
    }
    if (type === 'availibility') {
      if (selectedCatIds?.length === 0 || !serviceProvides) {
        setBusinessError('catError')
        return
      }
      formdata.append('businessCatagoryId', JSON.stringify(selectedCatIds?.map((item: any) => item?.value)));
      formdata.append('serviceProvides', serviceProvides);
    }
    if (type === 'regNumber') {
      formdata.append('registeredCompanyName', registeredCompanyName);
      formdata.append('ACRANumber', ACRANumber);
    }
    setBusinessError('')
    setBusinessLoading(type)
    let res = await httpRequest(
      `updateBusiness/${business?._id}`,
      "PATCH",
      formdata,
      "formdata"
    );
    handleAlertModalShow(`${res.status ? 'Success' : 'Error'}, ${res.message}`);
    if (res?.status) {
      let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
      getBusiness(userData)
      setIsEdit(initialState)
      if (userdata !== '' && userdata?._id) {
        let bid = '';
        if (userdata?.userType === "teamMember") {
          bid = `businessId=${userdata?.businessId}`;
        } else {
          bid = `id=${userdata?._id}`;
        }
        dispatch(getBusinessData({ id: bid }));
      }
    }

    setBusinessLoading('')
  }
  const handleBusinessAddressUpdate = async (data: any) => {
    const formdata = new FormData()
    formdata.append('city', data?.city || '')
    formdata.append('state', data?.state || '')
    formdata.append('country', data?.country || '')
    formdata.append('postalCode', data?.postalCode || '')
    formdata.append('streetName', data?.streetName || '')
    formdata.append('floor', data?.floor || '')
    formdata.append('landmarks', data?.landmarks || '')

    setBusinessLoading('address')
    let res = await httpRequest(
      `updateBusiness/${business?._id}`,
      "PATCH",
      formdata,
      "formdata"
    );
    handleAlertModalShow(`${res.status ? 'Success' : 'Error'}, ${res.message}`);
    if (res?.status) {
      let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
      getBusiness(userData)
      if (userdata !== '' && userdata?._id) {
        let bid = '';
        if (userdata?.userType === "teamMember") {
          bid = `businessId=${userdata?.businessId}`;
        } else {
          bid = `id=${userdata?._id}`;
        }
        dispatch(getBusinessData({ id: bid }));
      }
    }
    setIsEdit(initialState)
    setBusinessLoading('')
  }
  return (
    <>
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <StepperRow />
        <div className={`${st.newRedesign_main}`}>
          <Row className="align-items-center">
            <Col md={12}>
              <div className={`${st.head_paraBox} ${st.pt50}`}>
                <h2>Business</h2>
                <p>Provide address of the Business location. Welnezza support multiple branches.</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={9}>
              <Row>
                <Col md={8} className="mb-4 pb-3">
                  <div className={`${st.reFormBox}`}>
                    <label htmlFor="">Business Name</label>
                    {!isEdit?.businessName ? <div className="d-flex">
                      <input type="text" className="form-control" value={business?.businessName} placeholder="Enter Business Name" disabled />
                      {canEdit && <button className="btn" onClick={() => setIsEdit({ ...isEdit, businessName: true })}>
                        <img src={editButton} alt="edit" />
                      </button>}
                    </div> : (
                      <div className="d-flex gap-2">
                        <input type="text" className="form-control" value={businessName} placeholder="Enter Business Name" onChange={(e: any) => setBusinessName(e.target.value)} />
                        <button className={`${st.addGrey} btn`} onClick={() => handleBusiness('businessName')}>
                          {businessLoading === 'businessName' ? <LoadingBtn /> : 'Save'}
                        </button>
                        <button className={`${st.addCancel} btn`} onClick={() => {
                          setBusinessName(business?.businessName)
                          setIsEdit({ ...isEdit, businessName: false })
                        }}>
                          Cancel
                        </button>

                      </div>
                    )}
                    {businessError === 'businessName' && businessName === '' && (
                      <p className={"errorMessage mb-0"}>this field is required</p>
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={`${st.reFormBox}`}>
                    <label htmlFor="">Business Account ID:</label>
                    <h6 style={{ lineHeight: '30px', color: '#777C88' }}>{business?.businessAccountNo || ''}</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={9}>
              <div className={`${st.branchContact_tabBox}`}>
                <Col md={12}>
                  <div className={`${st.reFormBox}`}>
                    <label htmlFor="">Business Categories</label>
                    <div className={`${st.formBox} position-relative`}>
                      <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                        <MultiSelect2 disabled={!canEdit} value={selectedCatIds} onChange={(data: any) => setSelectedCatIds(data)} list={allBusCategories?.map((cat: any) => ({ label: cat?.BusinessCategoryName, value: cat?._id }))} />
                      </span>
                    </div>
                    {businessError === 'catError' && selectedCatIds?.length === 0 && (
                      <p className={"errorMessage mb-0"}>this field is required</p>
                    )}
                  </div>

                  <div className={`${st.reFormBox}`}>
                    <label htmlFor="">Service Available For</label>
                    <div>
                      <div className={`${st.edit_publish_checkBox} p-0`}>
                        <label className={`d-inline-flex ${st.radio}`}>
                          <input type='radio' disabled={!canEdit} value='female' checked={serviceProvides === 'female'} onChange={(e: any) => setServiceProvides(e.target.value)} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1 mt-1`}>Female</span>
                        </label>
                        <label className={`d-inline-flex ${st.radio}`}>
                          <input type='radio' disabled={!canEdit} value="male" checked={serviceProvides === 'male'} onChange={(e: any) => setServiceProvides(e.target.value)} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1 mt-1`} >Male</span>
                        </label>
                        <label className={`d-inline-flex ${st.radio}`}>
                          <input type='radio' disabled={!canEdit} value="unisex" checked={serviceProvides === 'unisex'} onChange={(e: any) => setServiceProvides(e.target.value)} />
                          <span className={`${st.checkmark}`}></span>
                          <span className={`${st.labelName} ms-1 mt-1`}
                          //  style={{ fontSize: '14px', lineHeight: 'inherit' }}
                          >Every one is Welcome</span>
                        </label>
                      </div>
                      {businessError === 'catError' && !serviceProvides && (
                        <p className={"errorMessage mb-0"}>this field is required</p>
                      )}
                    </div>

                    {canEdit && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button className={`${st.addGrey} btn mt-3`} onClick={() => handleBusiness('availibility')}>
                        {businessLoading === 'availibility' ? <LoadingBtn /> : 'Save'}
                      </button>
                    </div>}
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={3}>
              <div className={`${st.formBox}`}>
                <div className={`${cx.logoBox}`}>
                  <div className={cx.box}>
                    {canEdit && <button className={`${cx.upload_img_remove}`} onClick={removeLogo}>
                      {previewImage ? (<RxCross2 />) : ""}
                    </button>}

                    {previewImage ? (
                      <img
                        style={{
                          height: "150px",
                          width: "150px",
                          borderRadius: "15px",
                          objectFit: "cover",
                          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 4px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                          border: "1px solid gainsboro"
                        }}
                        src={previewImage}
                        alt="logo"
                      />
                    ) : (
                      <><AiFillCamera className={cx.boxImage} />
                        {canEdit && <span className={cx.bottomLogo}>
                          <label htmlFor="file">
                            <input
                              type="file"
                              id="file"
                              accept="image/*"
                              disabled={!canEdit}
                              onChange={onFileChange} />
                            <img src={upArrow} alt="logo" />
                          </label>
                        </span>}
                        </>
                    )}
                  </div>
                  {/* <p className={`${cx.upload} mb-0`}>
                    Upload Logo{" "} <HoverEyeContent number={9025} />
                  </p> */}
                  {/* {error?.includes('image') && (
                    <p className={"errorMessage"}>please upload logo image</p>
                  )} */}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={9}>
              <div className={`${st.branchContact_tabBox}`}>
                <Row>
                  <Col md={7}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">Registered Name of the Company</label>
                      <input type="text" className="form-control" disabled={!canEdit} placeholder="Registered Name of the Company" onChange={(e: any) => setRegisteredCompanyName(e.target.value)} value={registeredCompanyName} />
                    </div>
                  </Col>
                  {/* <Col md={3} className="ps-0">
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="" className="opacity-0">R</label>
                      <div>
                        <button className="btn px-0">
                          <img src={editButton} alt="edit" />
                        </button>
                      </div>
                    </div>

                  </Col> */}
                </Row>
                <Row>
                  <Col md={7}>
                    <div className={`${st.reFormBox}`}>
                      <label htmlFor="">ACRA registration Number</label>
                      <input type="text" className="form-control" disabled={!canEdit} placeholder="ACRA registration Number" onChange={(e: any) => setACRANumber(e.target.value)} value={ACRANumber} />
                    </div>
                  </Col>
                 {canEdit && <Col md={12}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button className={`${st.addGrey} btn mt-3`} onClick={() => handleBusiness('regNumber')}>
                        {businessLoading === 'regNumber' ? <LoadingBtn /> : 'Save'}
                      </button>
                    </div>
                  </Col>}
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className={`${st.formBox}`}>
                {/* <div className={`${cx.logoBox}`}>
                  <div className={cx.box}>
                    <button className={`${cx.upload_img_remove}`} onClick={removeLogo}>
                      {previewImage ? (<RxCross2 />) : ""}

                    </button>

                    {previewImage ? (
                      <img
                        style={{
                          height: "150px",
                          width: "150px",
                          borderRadius: "15px",
                          objectFit: "cover",
                          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 4px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                          border: "1px solid gainsboro"
                        }}
                        src={previewImage}
                        alt="logo"
                      />
                    ) : (
                      <><AiFillCamera className={cx.boxImage} />
                        <span className={cx.bottomLogo}>
                          <label htmlFor="file">
                            <input
                              type="file"
                              id="file"
                              accept="image/*"
                              disabled={!canEdit}
                              onChange={onFileChange} />
                            <img src={upArrow} alt="logo" />
                          </label>
                        </span></>
                    )}
                  </div>
                  <p className={`${cx.upload} mb-0`}>
                    Upload Logo{" "} 
                  </p>
                  {error?.includes('image') && (
                    <p className={"errorMessage"}>please upload logo image</p>
                  )}
                </div> */}
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xl={6} lg={7} md={9}>
              <div className={`${st.branchContact_tabBox}`}>
                <h4>Registered Address</h4>
                <ul className={`${st.contact_addressInputs}`}>
                  <li>
                    <label>Postal Code:</label>
                    <div className='w-100 position-relative'>
                      <input type="number" className='form-control' disabled={!canEdit} placeholder='Enter Your Postal Code' {...register('postalCode', { required: true })}
                      />
                      {errors?.postalCode?.type === 'required' && (
                        <p className={"errorMessage mb-0"}>this field is required</p>
                      )}
                    </div>
                  </li>
                  <li>
                    <label>Block & Street Name:</label>
                    <div className="w-100 position-relative">
                      <input type="text" className='form-control' disabled={!canEdit} placeholder='Enter Your Block & Street Name' {...register('address1', { required: false })} />
                    </div>
                  </li>
                  <li>
                    <label>Floor & Unit No.:</label>
                    <input type="number" className='form-control' disabled={!canEdit} placeholder='Enter Your Floor & Unit No.' {...register('floor', { required: false })} />
                  </li>
                  <li>
                    <label>Landmarks:</label>
                    <input type="text" className='form-control' disabled={!canEdit} placeholder='Enter Your Landmarks' {...register('landmarks', { required: false })} />
                  </li>
                  <li>
                    <label>City:</label>
                    <div className='w-100 position-relative'>
                      <input type="text" className='form-control' disabled={!canEdit} placeholder='Enter Your City' {...register('city', { required: true })} />
                      {errors?.city?.type === 'required' && (
                        <p className={"errorMessage mb-0"}>this field is required</p>
                      )}
                    </div>
                  </li>
                  {/* <li>
                    <label>State:</label>
                    <div className='w-100 position-relative'>
                      <input type="text" className='form-control' placeholder='Enter Your State' {...register('state', { required: false })} />
                    </div>
                  </li> */}
                  <li>
                    <label>Country:</label>
                    <div className='w-100 position-relative'>
                      <input type="text" disabled={!canEdit} className='form-control' placeholder='Enter Your Country ' {...register('country', { required: true })} />
                      {errors?.country?.type === 'required' && (
                        <p className={"errorMessage mb-0"}>this field is required</p>
                      )}
                    </div>
                  </li>
                </ul>
                {canEdit && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button className={`${st.addGrey} btn mt-3`} onClick={handleSubmit(handleBusinessAddressUpdate)}>
                    {businessLoading === 'address' ? <LoadingBtn /> : 'Save'}
                  </button>
                </div>}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default AddTeams;
