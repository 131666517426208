import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  bundles,
  clock,
  clockA,
  edit,
  packageActive,
  packageEmpty,
  packages,
  plus,
  rightIcon,
  schedule,
  serviceGreen,
  servicesActive,
  wrongIcon,
} from "../../../assets/images";
import cx from "./index.module.scss";
import { Col, Row } from "react-bootstrap";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { useSelector } from "react-redux";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../Firebase";
import NotificationItem from "./NotificationItem";

const Notification = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [notification, setNotification] = useState<any>([]);
  const [bookingFilter, setBookingFilter] = useState<string>('')
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (branchId) {
      const unSub = onSnapshot(
        doc(db, "notification", branchId),
        (doc: any) => {
          if(doc.exists()){
            const notification = doc.data().notification?.sort((a:any, b:any) => b?.date - a?.date)
            setNotification(notification)
          }else{
            setNotification([])
          }
        }
      );
      return () => {
        unSub();
      };
    }
  }, [branchId]);
  
  const filterNotification = notification?.filter((item: any) => {
    const statusCheck =  bookingFilter === 'active' ? item?.booking?.type !== 'Cancelled' : item?.booking?.type === 'Cancelled'
    return statusCheck || !bookingFilter
  })
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <Row className='align-items-center '>
          <Col md={12}>
            <div className="text-end">
              <HelpButon number={54} type={"help"} className={"btn"} />
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className={cx.pageTitle}>
              <h5>Notification</h5>
            </div>
          </Col>
          <Col lg={8} md={12}>
            <div className={`${cx.btnGroup}`}>
              <ul>
                <li>
                  <button className={`${cx.btn} ${bookingFilter === '' ? cx.active : ''}`} onClick={() => setBookingFilter('')}>All Booking</button>
                </li>
                <li>
                  <button className={`${cx.btn} ${bookingFilter === 'active' ? cx.active : ''}`} onClick={() => setBookingFilter('active')}>Active bookings</button>
                </li>
                <li>
                  <button className={`${cx.btn} ${bookingFilter === 'cancelled' ? cx.active : ''}`} onClick={() => setBookingFilter('cancelled')}>
                    Canceled Bookings
                  </button>
                </li>
                {/* <li>
                  <button className={`${cx.btn} `}>Reviews</button>
                </li> */}
              </ul>
            </div>

          </Col>
        </Row>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
          </div>
        </div>

        <div>
          <Row>
            {filterNotification?.map((item: any, i: number) => {
              return (
                <NotificationItem item={item} key={i} />
              )
            })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default Notification;
