import React from 'react';
import cx from './index.module.scss';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';

export default function DataCard({ statusPercentage, title, value, growth = true, text = 'Yesterday' }: { statusPercentage: string, title: string, value: string, growth?: boolean, text?: string }) {
    return (
        <div className={`${cx.upDownSale_card}`}>
            <h3>{title}</h3>
            <h2>{value}</h2>
            <div className={`${cx.upDown_percentMain}`}>
                <div className={`${cx.upDownPercentBox} ${cx.upPercentBox} ${!growth ? cx.downPercentBox : ''}`}>{growth ? <GoArrowUp style={{ fontSize: '15px', minWidth: '15px' }} /> : <GoArrowDown style={{ fontSize: '15px', minWidth: '15px' }} />}{statusPercentage}%</div>
                <p>Compared to {text}</p>
            </div>
        </div>
    )
}
