import { getDpName } from "../../../Utils/Funcs";
import cx from './index.module.scss';
export default function MyCustomResourceHeader(resource: any) {
    const isTeamAvailable = resource?.resource?.availablity?.toLowerCase() !== 'not available';
    const role = resource?.resource?.teamTitle ? resource?.resource?.teamTitle?.role?.map((title: any) => title?.teamTitle) : []
    return (
        // <div className={`${cx.teamBox} ${resource?.resource?.isUnassign ? cx.Unassigned : ""}`}>
        <>
            {resource?.label === "Total Available Slots" ? (
                <div className={`${cx.teamBox} ${cx.teamAvailablityBox}`}>
                    <div className={cx.contentBox}>
                        <p className={cx.label}>{resource?.label}</p>
                    </div>
                </div>
            ) : resource?.label === "Unassigned appointments" ? <div className={`${cx.teamBox} ${cx.Unassigned}`}>
                <div className={cx.contentBox}>

                    {/* <p className={cx.label}> <img src={unionLine} className={`${cx.icon}`} alt="union" /> {resource?.label}</p> */}
                    <p className={cx.label}>Unassigned <br /> appointments</p>
                </div>
            </div> : <div className={`${cx.teamBox}`}>
                {resource.resource.image ?
                    <div className={`${cx.imageBox}`}>
                        <img src={resource.resource.image} alt={resource.resource?.label} />
                    </div> :
                    <div className={`${cx.imageBox} ${cx.emotyBox}`} role='img'>
                        <span className={cx.logoFake}>{getDpName(resource?.label)}</span>
                    </div>
                }
                <div className={cx.contentBox}>
                    <p className={cx.label} title={resource?.label}>{resource?.label}</p>
                    <p className={`${cx.label} ${cx.title}`} title={role.join(", ")}>{role.join(", ")}</p>
                    <p className={isTeamAvailable ? cx.availablity : cx.notAvailable}>{resource?.resource?.availablity}</p>
                </div>
            </div>}
        </>
    );
};