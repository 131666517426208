import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Row } from "react-bootstrap";
import { Switch as CustomSwitch } from "../../../components/Website/Forms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues, weekValues } from "../../../constants/Constants";
import { disablePastDate } from "../../../Utils/Funcs";
import { Switch } from "@mui/material";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
import { copyClipBoard } from "../../../assets/images";

interface FormValues {
    [key: string]: string;
}

export default function OpHrs(props: any) {
    const { handleStepsFormData, editBranchData, loading } = props;

    const { register, setValue, control, handleSubmit, watch, formState: { errors }, reset } = useForm<FormValues>();
    const {
        register: regNonOpHrs,
        control: controlNonOpHrs,
        handleSubmit: handleSubmitNonOpHrs,
        watch: watchNonOpHrs,
        setValue: setNonOpHrsValue,
        formState: { errors: NonOpHrsErr },
        reset: resetNonOpHrs,
    } = useForm<FormValues>();

    const [selectedNonOpHrs, setSelectedNonOpHrs] = useState<any>([]);
    const [dayWiseOff, setDayWiseOff] = useState<any>([]);
    const [isSameEveryDay, setIsSameEveryDay] = useState(false);
    const [fullDayCheck, setFullDayCheck] = useState(false);
    const [temworking, setWorking] = useState<boolean>(true)

    console.log(editBranchData, 'editBranchData');
    
    useEffect(() => {
        if (editBranchData) {

            let timeCounts: any = {}
            for (const entry of editBranchData?.workingHours) {
                const combinationKey = `${entry.from}-${entry.to}`;
                if (combinationKey in timeCounts) {
                    timeCounts[combinationKey]++;
                } else {
                    timeCounts[combinationKey] = 1;
                }
            }
            const uniqueTimeCombinations = editBranchData?.workingHours.filter((entry: any) => {
                const combinationKey = `${entry.from}-${entry.to}`;
                return timeCounts[combinationKey] === 1;
            });

            reset();
            // resetNonOpHrs();
            editBranchData.workingHours?.map((item: any) => {
                setValue(`${item.dayName}from`, item.from === "Closed" ? "" : item.from);
                setValue(`${item.dayName}to`, item.to === "Closed" ? "" : item.to);
            })
            setDayWiseOff(editBranchData.workingHours?.map((item: any) => ({ day: item.dayName, value: item.from === "Closed" })))
            if (uniqueTimeCombinations.length > 0) {
                setIsSameEveryDay(false)
                setValue(`from`, "N/A");
                setValue(`to`, "N/A");
            } else {
                setIsSameEveryDay(editBranchData.sameEveryDay?.isSameEveryDay)
                setValue(`from`, editBranchData.sameEveryDay?.from);
                setValue(`to`, editBranchData.sameEveryDay?.to);
            }
            // setSelectedNonOpHrs(editBranchData.notWorkingHours);
        }
    }, [editBranchData]);


    const handleSwitchChange = (value: any) => {
        let isChecked = value
        let fromVal = isChecked ? watch('from', '') : '';
        let toVal = isChecked ? watch('to', '') : '';
        weekValues.forEach((item, index) => {
            if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                return
            }
            setValue(`${item.label}from`, fromVal);
            setValue(`${item.label}to`, toVal);
        });
        setIsSameEveryDay(value);
    };

    useEffect(() => {
        if (dayWiseOff?.length !== 0) {
            dayWiseOff?.forEach((item: any, index: number) => {
                item?.value && setValue(`${item.day}from`, "");
                item?.value && setValue(`${item.day}to`, "");
            })
        }
    }, [dayWiseOff])

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        let workingHours: any = [];
        weekValues.forEach((elem, index) => {
            workingHours.push({
                dayName: elem.label,
                from: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}from`],
                to: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}to`],
            })
        });
        let sameEveryDayObj = { isSameEveryDay: isSameEveryDay, from: data.from, to: data.to };
        let allData = {
            workingHours: JSON.stringify(workingHours),
            sameEveryDay: JSON.stringify(sameEveryDayObj),
        }
        handleStepsFormData(allData, 'step3');
    };

    // const sameFromValue = watch('from')
    // const sameToValue = watch('to')

    // useEffect(() => {
    //     if (isSameEveryDay) {
    //         weekValues.forEach((item, index) => {
    //             if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
    //                 return
    //             }
    //             item?.value && setValue(`${item.label}from`, sameFromValue);
    //         });
    //     }
    // }, [sameFromValue])
    // useEffect(() => {
    //     if (isSameEveryDay) {
    //         weekValues.forEach((item, index) => {
    //             if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
    //                 return
    //             }
    //             item?.value && setValue(`${item.label}to`, sameToValue);
    //         });
    //     }
    // }, [sameToValue]);
    console.log(errors, 'errors');
    
    return (
        <>
            <div className={`${st.newRedesign_main} mb-4`}>
                <Row>
                    <Col xl={7} lg={7} md={8}>
                        <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} `}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Branch Operating Hours</h4>
                                <button type='submit' className="saveButton" disabled={loading} onClick={handleSubmit(onSubmit)}>
                                    {loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        ></div>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                            <p>Please maintain the working hours for the branch. These
                                working hours would be visible on Wellnezza and would be used in booking funcations</p>

                            <ul className={`${st.branchOperating_tab_InputMian}`}>
                                <li>
                                    <div className={`${st.firstBox} ${st.copyAllDays}`}>
                                        <label className={`d-inline-flex ${st.checkbox}`}>
                                            <input type="checkbox"
                                                checked={isSameEveryDay}
                                                // onChange={handleSwitchChange} 
                                                />
                                            <span className={`${st.checkmark}`}></span>
                                            <span className={`${st.labelName} ms-0`}>Copy to <br /> all days</span>
                                        </label>
                                    </div>
                                    <div className={`${st.secondBox}`}>
                                        <div className={`${st.inoutArea}`}>
                                            <label>Opens at</label>
                                            {/* <input type="text" className="form-control" placeholder="Enter Time" /> */}
                                            <Controller
                                                name="from"
                                                control={control}
                                                rules={{ required: false }}
                                                render={({ field }) => {
                                                    let timeData: any = [];
                                                    // if (isSameEveryDay === true && isSameEveryDay !== undefined) {
                                                    //     timeData = timeValues
                                                    // }
                                                    return (
                                                        <select className="form-select" value={field.value} onChange={field.onChange}>
                                                            <option value="">-Select-</option>
                                                            {timeValues?.map((time: any, i: number) => {
                                                                return (
                                                                    <option value={time?.value} key={i}>{time?.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    )
                                                }}
                                            />
                                            {errors?.from?.type === "required" && (
                                                <p className="errorMessage mb-0">required*</p>
                                            )}

                                        </div>
                                    </div>
                                    <div className={`${st.secondBox}`}>
                                        <div className={`${st.inoutArea}`}>
                                            <label>Closes at</label>
                                            <Controller
                                                name="to"
                                                control={control}
                                                rules={{ required: false }}
                                                render={({ field }) => {
                                                    let timeData: any = timeValues.filter((item: any) => item.value >= watch('from', ''))
                                                    // if (isSameEveryDay === true && isSameEveryDay !== undefined) {
                                                    // }
                                                    return (
                                                        <select className="form-select" value={field.value} onChange={field.onChange}>
                                                            <option value="">-Select-</option>
                                                            {timeData?.map((time: any, i: number) => {
                                                                return (
                                                                    <option value={time?.value} key={i}>{time?.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    )
                                                }}
                                            />
                                            {errors?.to?.type === "required" && (
                                                <p className={"errorMessage mb-0"}>required*</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${st.lastCopyClip}`}>
                                        <button onClick={() => handleSwitchChange(true)}>
                                            <img src={copyClipBoard} alt="copy" />
                                        </button>
                                    </div>
                                </li>
                                {weekValues.map((item: any, index: number) => {
                                    return (
                                        <li key={index}>
                                            <div className={`${st.firstBox}`}>
                                                <h6>{item?.label}</h6>
                                                <label className={`d-inline-flex ${st.checkbox}`}>
                                                    <input type="checkbox" checked={dayWiseOff?.[index]?.value}
                                                        // disabled={isSameEveryDay}
                                                        onChange={(e: any) => {
                                                            let updateDayWiseOff: any = [...dayWiseOff];
                                                            updateDayWiseOff[index] = {
                                                                day: item?.label,
                                                                value: e.target.checked
                                                            }
                                                            setDayWiseOff(updateDayWiseOff)
                                                        }} />
                                                    <span className={`${st.checkmark}`}></span>
                                                    <span className={`${st.labelName} ms-1`} >Closed</span>
                                                </label>
                                            </div>
                                            {!dayWiseOff?.[index]?.value && (
                                                <>
                                                    <div className={`${st.secondBox}`}>
                                                        <div className={`${st.inoutArea}`}>
                                                            <label>Opens at</label>
                                                            <Controller
                                                                name={`${item.label}from`}
                                                                control={control}
                                                                rules={{ required: !dayWiseOff?.[index]?.value }}
                                                                render={({ field }) => (
                                                                    <select className="form-select" disabled={dayWiseOff?.[index]?.value} value={field?.value} onChange={field.onChange}>
                                                                        <option value="">-Select-</option>
                                                                        {timeValues?.map((item: any, t: number) => {
                                                                            return (
                                                                                <option value={item?.value} key={t}>{item?.label}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                )}
                                                            />
                                                            {errors[`${item.label}from`]?.type === "required" && (
                                                                <span className="errorMessage mb-0">required*</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={`${st.secondBox}`}>
                                                        <div className={`${st.inoutArea}`}>
                                                            <label>Closes at</label>

                                                            <Controller
                                                                name={`${item.label}to`}  // This should match your form data structure
                                                                control={control}
                                                                rules={{ required: !dayWiseOff?.[index]?.value }}
                                                                render={({ field }) => {
                                                                    const filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));
                                                                    return (
                                                                        <select className="form-select" disabled={dayWiseOff?.[index]?.value} value={field?.value} onChange={field.onChange}>
                                                                            <option value="">-Select-</option>
                                                                            {filteredTimeValues?.map((item: any, t: number) => {
                                                                                return (
                                                                                    <option value={item?.value} key={t}>{item?.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        // <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={false} />
                                                                    );
                                                                }}
                                                            />
                                                            {errors[`${item.label}to`]?.type === "required" && (
                                                                <span className="errorMessage mb-0">required*</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <Row>
                <Col md={12}>
                    <div className="text-end">
                        <HelpButon number={36} type={"help"} className={"btn"} />
                    </div>
                </Col>
            </Row> */}
            {/* <Row>
                <Col lg={12}>
                    <div className={`${cx.everyDay}`}>
                        <Row>
                            <Col md={3}>
                                <div className={`${cx.everyDaySwith} align-items-center`}>
                                    <label>Same every day</label>
                                    <Switch
                                        checked={isSameEveryDay}
                                        onChange={handleSwitchChange}
                                    />
                                    <HoverEyeContent number={9084} />
                                </div>
                            </Col>
                            <Col md={6}>
                                {<div
                                    className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                                >
                                    <div className={`${st.formBox}`} onClick={handleTimeChange}>
                                        <Controller
                                            name="from"
                                            control={control}
                                            rules={{ required: false }}
                                            render={({ field }) => {
                                                let value: any = [];
                                                if (isSameEveryDay == true && isSameEveryDay !== undefined) {
                                                    value = timeValues
                                                }
                                                return (<CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={value} required={false} />
                                                )
                                            }}
                                        />
                                        {errors?.from?.type === "required" && (
                                            <p className="errorMessage">required*</p>
                                        )}
                                    </div>

                                    <div className={`${st.formBox}`} onClick={handleTimeChange}>
                                        <Controller
                                            name="to"
                                            control={control}
                                            rules={{ required: false }}
                                            render={({ field }) => {
                                                let value: any = [];
                                                if (isSameEveryDay == true && isSameEveryDay !== undefined) {
                                                    value = timeValues.filter((item: any) => item.value >= watch('from', ''))
                                                }
                                                return (
                                                    <CustomTimeSelect value={field.value} onChange={field.onChange}
                                                        timeValues={value}
                                                        required={false} />
                                                )
                                            }}
                                        />
                                        {errors?.to?.type === "required" && (
                                            <p className={"errorMessage"}>required*</p>
                                        )}
                                    </div>
                                </div>}
                            </Col>
                        </Row>
                    </div>
                </Col>

                {weekValues.map((item: any, index: number) => {
                    return (
                        <Col xl={3} lg={4} md={6} key={item.value}>
                            <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">{item.label}</label>
                                    <Controller
                                        name={`${item.label}from`}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px", minWidth: "84px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange}
                                                timeValues={timeValues} required={false} />
                                        )}
                                    />
                                    {errors[`${item.label}from`]?.type === "required" ? (
                                        <span className="errorMessage">required*</span>
                                    ) : <span>&nbsp;</span>
                                    }
                                </div>
                                <div className={`${st.formBox}`}>
                                    <div className="d-flex align-items-center justify-content-end" style={{ height: '30px' }}>
                                        <label className="form-label ms-2 text-nowrap">Closed :</label>
                                        <div style={{ transform: "translate(5%, -11%)" }}>
                                            <Switch
                                                checked={dayWiseOff?.[index]?.value}
                                                disabled={isSameEveryDay}
                                                onChange={(e: any) => {
                                                    let updateDayWiseOff: any = [...dayWiseOff];
                                                    updateDayWiseOff[index] = {
                                                        day: item?.label,
                                                        value: e.target.checked
                                                    }
                                                    setDayWiseOff(updateDayWiseOff)
                                                }}
                                            />
                                        </div>

                                    </div>


                                    <Controller
                                        name={`${item.label}to`} 
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => {
                                            const filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));
                                            return (
                                                <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={false} />
                                            );
                                        }}
                                    />
                                    {errors[`${item.label}to`]?.type === "required" ? (
                                        <span className="errorMessage">required*</span>
                                    ) : <span>&nbsp;</span>
                                    }
                                </div>
                            </div>
                        </Col>
                    )
                })
                }
            </Row> */}
            {/* <Row>
                <Col md={12} className="mt-2">
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <h2>Non Operating Hours</h2>
                    </div>
                </Col>
            </Row> */}

            {/* <Row>
                <Col xl={3} lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.dateBox} `}>
                        <label htmlFor="from" className="form-label">
                            From
                        </label>
                        <div className="position-relative">
                            <input
                                type="date"
                                id="from"
                                className={`${cx.dobInput} form-control`}
                                min={disablePastDate()}
                                {...regNonOpHrs("fromDate", {
                                    required: false,
                                })}
                            />
                           
                        </div>
                        {NonOpHrsErr?.fromDate?.type === "required" && (
                            <p className="errorMessage">required*</p>
                        )}
                    </div>
                </Col>

                <Col xl={3} lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.dateBox} `}>
                        <label htmlFor="to" className="form-label">
                            To
                        </label>
                        <div className="position-relative">
                            <input
                                type="date"
                                id="from"
                                min={watchNonOpHrs('fromDate', disablePastDate())}
                                className={`${cx.dobInput} form-control`}
                                {...regNonOpHrs("toDate", {
                                    required: false,
                                })}
                            />
                           
                        </div>
                        {NonOpHrsErr?.toDate?.type === "required" && (
                            <p className="errorMessage">required*</p>
                        )}
                    </div>
                </Col>

                <Col lg={4} md={12}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Full Day</label>
                        <br />
                        <div className={` ${cx.timeCheckbox}`}>
                            <label className={`${st.checkbox}`}>
                                <input type="checkbox" checked={fullDayCheck} onChange={handleCheckBoxSelection} />
                                <span className={`${st.checkmark}`}></span>
                                <span className="ms-2"></span>
                            </label>
                            {!fullDayCheck && <>
                                <Controller
                                    name="from"
                                    control={controlNonOpHrs}
                                    rules={{ required: !fullDayCheck }}
                                    render={({ field }) => (
                                        <CustomTimeSelect value={field.value} onChange={field.onChange}
                                            timeValues={timeValues}
                                            required={!fullDayCheck} />
                                    )} />
                                <Controller
                                    name="to"
                                    control={controlNonOpHrs}
                                    rules={{ required: !fullDayCheck }}
                                    render={({ field }) => (
                                        <>
                                            <CustomTimeSelect value={field.value} onChange={field.onChange}
                                                timeValues={timeValues.filter((item: any) => item.value >= watchNonOpHrs('from', ''))}
                                                required={!fullDayCheck} />
                                        </>
                                    )} />
                            </>}

                        </div>
                        {(NonOpHrsErr?.from?.type === "required" || NonOpHrsErr?.to?.type === "required") && (
                            <p className="errorMessage">required*</p>
                        )}
                    </div>
                </Col>

                <Col lg={1}>
                    <button className={`${cx.plusBtn} btn`} onClick={handleSubmitNonOpHrs(onNonOpHrsSubmit)}>Save</button>
                </Col>
            </Row>
            {selectedNonOpHrs?.map((item: any, i: any) => {
                return (
                    <Row key={i}>
                        <Col xl={3} lg={4} md={6}>
                            <div className={`${st.formBox} ${cx.dateBox} `}>
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        id="from"
                                        className={`${cx.dobInput} form-control`}
                                        disabled
                                        defaultValue={item.fromDate}
                                    />
                                </div>
                            </div>
                        </Col>

                        <Col xl={3} lg={4} md={6}>
                            <div className={`${st.formBox} ${cx.dateBox} `}>
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        id="from"
                                        className={`${cx.dobInput} form-control`}
                                        disabled
                                        defaultValue={item.toDate}
                                    />
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={12}>
                            <div className={`${st.formBox}`}>
                                <div className={` ${cx.timeCheckbox}`}>
                                    <label className={`${st.checkbox}`}>
                                        <input type="checkbox" checked={item.isFullDay === 'true' || item.isFullDay} disabled />
                                        <span className={`${st.checkmark}`}></span>
                                        <span className="ms-2"></span>
                                    </label>
                                    {item.from !== '' && <select className="form-select" disabled>
                                        <option>{item.from}</option>
                                    </select>}
                                    {item.from !== '' && <select className="form-select" disabled>
                                        <option>{item.to}</option>
                                    </select>}
                                </div>
                            </div>
                        </Col>

                        <Col lg={1}>
                            <button className={`${cx.plusBtn} grayout btn mt-0`} onClick={(e) => nonOpHrsRemoveChangeHandler(e, i)}>-</button>
                        </Col>
                    </Row>
                )
            })} */}
            {/* <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => handleStepsFormData('prevStep', 'about')}
                            >
                                Back
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`} disabled={loading} onClick={handleSubmit(onSubmit)}>
                                {loading ? (
                                    <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                    ></div>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row> */}
        </>
    )
}
